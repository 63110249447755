import classnames from "classnames";
import React, { Fragment } from "react";
import { useTable } from "react-table";
import PropTypes from "prop-types";

const Table = ({ className, data, columns, previewIndex, preview }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className={classnames(className)}>
      <table {...getTableProps()} style={{ width: "100%" }}>
        <thead>
          {headerGroups.map((headerGroup, headerGroupIdx) => {
            const { key: headerKey, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={headerKey || headerGroupIdx} {...headerGroupProps}>
                {headerGroup.headers.map((column, columnIdx) => {
                  const { key: columnKey, ...columnProps } = column.getHeaderProps();
                  return (
                    <th key={columnKey || columnIdx} {...columnProps}>
                      {column.render("Header")}
                    </th>
                  );
                })}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIdx) => {
            prepareRow(row);
            const { key: rowKey, ...rowProps } = row.getRowProps();
            return (
              <Fragment key={rowKey || rowIdx}>
                <tr key={rowKey} {...rowProps}>
                  {row.cells.map((cell, cellIdx) => {
                    const { key: cellKey, ...cellProps } = cell.getCellProps();
                    return (
                      <td key={cellKey || cellIdx} {...cellProps}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
                {rowIdx === previewIndex && preview && (
                  <tr className="preview-row">
                    <td colSpan={columns.length}>
                      <div>{preview}</div>
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  columns: PropTypes.array,
  preview: PropTypes.node,
  previewIndex: PropTypes.number,
};

export default Table;
