import React from "react";
import PropTypes from "prop-types";
import COLOURS from "../../../theme/base/colours";

const ActionCircleSolid = ({
  width = "18",
  height = "18",
  fill = COLOURS.ACTION_GREEN,
}) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle fill={fill} cx="9" cy="9" r="9" />
      <path
        d="M12 6.662a.662.662 0 01-.662.662H9.876v5a.876.876 0 11-1.752 0v-5H6.662a.662.662 0 110-1.324h4.676c.366 0 .662.296.662.662z"
        fill={COLOURS.WHITE}
        fillRule="nonzero"
      />
    </g>
  </svg>
);

ActionCircleSolid.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default ActionCircleSolid;
