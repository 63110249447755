import React from "react";
import PropTypes from "prop-types";

import Header from "../../Header";
import Navigation from "../../../components/Navigation";

const SkeletonDefaultPageCampaignManagement = ({
  className,
  hasNav = true,
}) => {
  return (
    <div className={className}>
      <Header>
        {hasNav && <Navigation />}
        <div className="skeleton__page-heading">
          <span className="skeleton__page-title" />
          <span className="skeleton__page-user" />
        </div>
        <div className="skeleton__pageContainer">
          <span className="skeleton__pageInfo" />
        </div>
      </Header>
      <div className="skeleton__page-content" />
    </div>
  );
};

SkeletonDefaultPageCampaignManagement.propTypes = {
  className: PropTypes.string,
  hasNav: PropTypes.node,
};

export default SkeletonDefaultPageCampaignManagement;
