import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const ArrowRefresh = ({
  strokeColour,
  width = "26",
  height = "24",
  strokeWidth = "2",
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M20.859 19.865A10.854 10.854 0 0010.07 1.617" />
        <path
          fill={strokeColour}
          d="M7.006 9.09V3.95H1.864zM19.859 14.803v5.142H25z"
        />
        <path d="M6.006 4.033a10.854 10.854 0 0011.371 18.034" />
      </g>
    </svg>
  );
};

ArrowRefresh.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default ArrowRefresh;
