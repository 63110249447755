import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const InfoCircle = ({
  width = "34",
  height = "34",
  strokeWidth = "2",
  strokeColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="16" cy="16.001" r="15.644" />
        <path d="M16 23.876V12.452a.711.711 0 00-.711-.712h-2.133M15.637 8.178a.356.356 0 100 .71.356.356 0 000-.71" />
      </g>
    </svg>
  );
};

InfoCircle.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default InfoCircle;
