import React, { useContext } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import HeaderText from "./../../HeaderText";
import ParagraphText from "./../../ParagraphText";
import Panel from "./../../Panel";
import Icon from "./../../Icon";
import { ThemeContext } from "styled-components";
import { useMediaQuery } from "react-responsive";
import breakpoints from "./../../../theme/breakpoints";

const DealerFundingRequestError = ({
  className,
  qaClassName = "qa-dealer-funding-request-error",
  fundingApplicationData,
  onClose,
}) => {
  const theme = useContext(ThemeContext);

  const isDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });

  return (
    <div className={classnames(className, qaClassName)}>
      <Panel flex="1" padding={isDesktop ? "NONE" : "REGULAR"}>
        <div className="header">
          <Icon
            type="error-circle"
            width="40"
            height="40"
            strokeColour={theme.COLOURS.RED.base}
          />
          <HeaderText>Funding Allocation Failed</HeaderText>
        </div>
        <ParagraphText>
          Your request to allocate the vehicle purchase to the funding plan has
          failed. Please see the below details of the failure for your
          reference.
        </ParagraphText>
      </Panel>

      <Panel flex="1" padding={isDesktop ? "NONE" : "REGULAR"}>
        <div className="modal-content">
          <Icon
            type="alert-triangle"
            width="40"
            height="40"
            strokeColour={theme.COLOURS.RED.base}
          />
          <ParagraphText>{fundingApplicationData.errorMessage}</ParagraphText>
        </div>
      </Panel>

      <Panel
        flex="1"
        padding={isDesktop ? "NONE" : "REGULAR"}
        actions={[
          {
            content: "Close",
            action: onClose,
            type: "ghost",
          },
        ]}
      />
    </div>
  );
};

DealerFundingRequestError.propTypes = {
  className: PropTypes.string,
  qaClassName: PropTypes.string,
  onClose: PropTypes.func,
  fundingApplicationData: PropTypes.object,
};

export default DealerFundingRequestError;
