import React, { useEffect, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { ThemeContext } from "styled-components";
import useClickOutside from "../../../shared/hooks/useClickOutside";
import InputLabel from "../InputLabel";
import InputText from "../InputText";
import Icon from "../Icon";
import Notification from "../Notification";

const InputSearch = ({
  className,
  onInputClear,
  isFetching,
  isClearInput,
  onRegSearch,
  defaultValue,
  title = "Reg Search",
  placeholderText = "e.g. RE33 NUM",
  errorMessage = "Please enter a valid reg number",
  onTermLookup = () => {},
  maxLength = "15",
  validationPattern = /^[0-9A-Za-z\s]+$/,
  removeWhiteSpaces = true,
}) => {
  const [value, setValue] = useState("");
  const [validValue, setValidValue] = useState(true);
  const [hasFocus, setHasFocus] = useState(false);
  const [showClear, setShowClear] = useState(false);

  const theme = useContext(ThemeContext);

  const formRef = useRef(null);
  useClickOutside(formRef, () => {
    setHasFocus(false);
    setValidValue(true);
  });

  useEffect(() => {
    if (!value || isClearInput) {
      setShowClear(false);
    }
  }, [value, isClearInput, setShowClear]);

  useEffect(() => {
    if (isClearInput) {
      setValue("");
    }
  }, [isClearInput, setValue]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
      setShowClear(true);
    }
  }, [defaultValue]);

  const onSearch = () => {
    // eslint-disable-next-line no-useless-escape
    if (value.length > 0 && value.match(validationPattern)) {
      setValidValue(true);
      setShowClear(true);
      onTermLookup(
        removeWhiteSpaces ? value.replace(/\s+/g, "") : value.trim()
      );
    } else {
      setValidValue(false);
    }
    setHasFocus(true);

    if (onRegSearch) {
      onRegSearch();
    }
  };

  const handleClear = () => {
    setValue("");
    setShowClear(false);
    onInputClear();
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
    if (!value.length) {
      handleClear();
    }
  };

  const handleFocus = (e) => {
    e.target.select();
    setHasFocus(true);
  };

  return (
    <div
      className={classnames(className, {
        "has-error": !validValue,
      })}
    >
      {title && <InputLabel children={title} />}
      <div className="input-search__form" ref={formRef}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            return onSearch();
          }}
        >
          <InputText
            type="text"
            placeholder={placeholderText}
            value={value}
            disabled={isFetching}
            maxLength={maxLength}
            onChange={(e) => handleChange(e)}
            onFocus={(e) => handleFocus(e)}
            onBlur={() => {
              if (hasFocus) {
                setHasFocus(false);
                setValidValue(true);
              }
              if (!value.length) {
                handleClear();
              }
            }}
            validValue={validValue}
          />
          <div className="input-search__actions">
            {value && showClear ? (
              <span
                className="input-search__clear"
                onClick={() => handleClear()}
                title="Clear search"
              >
                <Icon
                  type="exclude"
                  strokeColour={theme.COLOURS.WHITE}
                  strokeWidth="2"
                  width="20"
                  height="20"
                />
              </span>
            ) : (
              <button className="input-search__button" title="Search">
                <Icon
                  type="search"
                  strokeColour={theme.COLOURS.WHITE}
                  strokeWidth="3"
                  width="20"
                  height="20"
                />
              </button>
            )}
          </div>

          {!validValue && hasFocus && (
            <Notification
              className="input-search__error"
              title={errorMessage}
              size="small"
              isAlert
              type="error"
              overrideIcon={{ type: "danger", strokeWidth: "2" }}
            />
          )}
        </form>
      </div>
    </div>
  );
};

InputSearch.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  placeholderText: PropTypes.string,
  errorMessage: PropTypes.string,
  onTermLookup: PropTypes.func,
  maxLength: PropTypes.string,
  isFetching: PropTypes.bool,
  isClearInput: PropTypes.bool,
  validationPattern: PropTypes.instanceOf(RegExp),
  removeWhiteSpaces: PropTypes.bool,
};

export default InputSearch;
