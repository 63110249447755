import Api from "../Base";
import settings from "../../clientappsettings.json";
import { getResponseObjectProperties } from "../../shared/utility";

class SalesInformation extends Api {
  baseUrl = settings.VENDORS_URL;
  headers = {
    Authorization: `Bearer ${this.getCredentials()}`,
  };
}

export class FetchVehicleRecordSalesInfo extends SalesInformation {
    requestUrl = `/SalesInfo/${this.getParams().vehicleId}`;
    formatResponseData(response) {
  
      const salesInfoDetails = [
        "buyNowPrice",
        "bidStartAtPrice",
        "auctionReservePrice",
        "onlineReservePrice",
        "retailPrice",
        "conditionAtCapValuation",
        "thirdPartyValuation",
        "salesAccount",
        "salesAccountId",
        "salesText",
        "vatStatus",
        "saleCategory",
        "vehicleDamageTotal",
        "residualValue",
        "additionalPrepCosts",
        "biddingEndDate",
        "currentValuation",
        "currentValuationDate",
        "biddingType",
        "biddingEnabled",
        "biddingEndDate",
        "saleCategoryOptions",
        "salesAccountOptions",
        "vatStatusOptions"
      ];
  
      return {
        salesInfo: getResponseObjectProperties(response.data, salesInfoDetails, true),
      };
    }
  }

  export class UpdateSalesInformation extends SalesInformation {
    method = "POST";
    requestUrl = `/Pricing/Update`;
    formatResponseData(response) {
      return response?.data;
    }
  }