import React, { Fragment, useRef, useState, useEffect } from "react";
import { isAddressValid } from "../../../shared/utility";
import classnames from "classnames";
import ParagraphText from "../ParagraphText";
import Button from "./../Button";
import AddressField from "./../AddressField";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../theme/breakpoints";
import * as consts from "./const";

const AddressPicker = ({
  onFind,
  onCapture,
  foundLocations,
  selectedLocation,
  onCancel,
  onConfirmLocation,
  qaClassName,
  className,
  hideButtons,
  standalonePicker,
  address,
  setUpdatedAddress,
  setLocationName,
  locationName = "",
  splitCompanyNameFromAddress = false,
  setValidAddress,
}) => {
  const [text, setText] = useState("");
  const [timer, setTimer] = useState(null);
  const [displayResults, setDisplayResults] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const [location, setLocation] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (standalonePicker) {
      setUpdatedAddress(location?.address);
      setValidAddress(!hasError);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.address, hasError]);

  useEffect(() => {
    if (address && Array.isArray(address)) {
      const formattedAddress = address.join("\n");
      if (
        splitCompanyNameFromAddress &&
        standalonePicker &&
        location?.companyName &&
        formattedAddress.startsWith(location.companyName)
      ) {
        const formattedWithoutFirstLine = formattedAddress.substring(
          formattedAddress.indexOf("\n") + 1
        );
        setLocation((prev) => ({
          ...prev,
          address: formattedWithoutFirstLine,
        }));
      } else {
        setLocation((prev) => ({ ...prev, address: formattedAddress }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, standalonePicker, location?.companyName, locationName]);

  const isCompanyNameValid = () => {
    const firstLine = location?.address?.substr(
      0,
      location.address.indexOf("\n")
    );
    return firstLine === location?.companyName;
  };

  useEffect(() => {
    if (standalonePicker) {
      if (location && location.companyName !== undefined) {
        if (location.companyName === "") {
          setLocationName("");
        } else if (!locationName || locationName !== location.companyName) {
          setLocationName(location.companyName);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const addressValidation = (location) => {
    const { isValid, error } = isAddressValid(location);
    if (!isValid) {
      setHasError(true);
      setError(error);
      return false;
    }
    setHasError(false);
    setError("");
    return { isValid, error };
  };

  const onConfirmHandle = () => {
    var { isValid } = addressValidation(location.address);
    if (isValid) {
      return isCompanyNameValid()
        ? onConfirmLocation(location)
        : onConfirmLocation({ ...location, companyName: "" });
    }
  };

  const resultListRef = useRef(null);

  useEffect(() => {
    if (selectedLocation && selectedLocation.length) {
      selectedLocation.forEach((el) => {
        const hasCompanyName = location?.companyName || locationName;
        setLocation({
          address:
            splitCompanyNameFromAddress && hasCompanyName
              ? el.Label.substring(
                  el.Label.indexOf("\n") + 1,
                  el.Label.lastIndexOf("\n")
                )
              : el.Label.substring(0, el.Label.lastIndexOf("\n")),
          postCode: el.PostalCode,
          companyName: el.Company,
        });
        addressValidation(location?.address);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, location?.companyName, locationName]);

  const manualLocationHandler = (e) => {
    const address = e.target.value;
    setLocation((prev) => ({
      ...prev,
      address: address,
      postCode: address.substr(address.lastIndexOf("\n") + 1, address.length),
    }));
    addressValidation(address);
  };

  const textHandler = (event) => {
    clearTimeout(timer);
    setText(event.target.value);

    if (event.target.value.length) {
      setTimer(setRequestTimeout(event.target.value));
    }
  };

  const inputKeyDownHandler = (event) => {
    if (resultListRef && resultListRef.current) {
      if (event.keyCode === 40) {
        //down
        setCursor((prevCursor) => {
          if (prevCursor < resultListRef.current.children.length - 1) {
            resultListRef.current.children[prevCursor + 1].scrollIntoView(
              false
            );
            return prevCursor + 1;
          }
          resultListRef.current.children[0].scrollIntoView();
          return 0;
        });
      }

      if (event.keyCode === 38) {
        //up
        setCursor((prevCursor) => {
          if (prevCursor > 0) {
            resultListRef.current.children[prevCursor - 1].scrollIntoView();
            return prevCursor - 1;
          }
          resultListRef.current.children[
            resultListRef.current.children.length - 1
          ].scrollIntoView();
          return resultListRef.current.children.length - 1;
        });
      }

      if (event.keyCode === 13) {
        //enter
        if (cursor !== -1) {
          resultListRef.current.children[cursor].click();
        }
        setCursor(-1);
      }
    }
  };

  const setRequestTimeout = (value) => {
    return setTimeout(() => {
      onFind(value);
      setDisplayResults(true);
    }, 500);
  };

  const selectHandler = (location) => {
    if (location.Type !== "Address") {
      onFind(location.Text, location.Id);
    } else {
      setDisplayResults(false);
      onCapture(location.Id);
    }
  };

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.PHONE})`,
  });

  const confirmText = !isMobile
    ? consts.CONFIRM_AND_USE_THIS_ADDRESS
    : consts.CONFIRM;

  return (
    <div className={classnames(className, qaClassName)}>
      {!standalonePicker && (
        <>
          <div className="title">{consts.ADD_ADDRESS}</div>
          <ParagraphText>{consts.HELP_TEXT} </ParagraphText>
          {splitCompanyNameFromAddress && (
            <ParagraphText>
              {consts.COMPANY_NAME_REMOVAL_HELP_TEXT}
            </ParagraphText>
          )}
        </>
      )}
      <div className="address-picker">
        <div className="address-input">
          <p>{consts.FIND_YOUR_ADDRESS}</p>
          <input
            type="text"
            value={text}
            onChange={textHandler}
            onKeyDown={inputKeyDownHandler}
          />
        </div>
        {displayResults && foundLocations && text && (
          <Fragment>
            <div className="popover__arrow"></div>
            <div className="result-list" ref={resultListRef}>
              {foundLocations.length ? (
                foundLocations.map((el, idx) => (
                  <div
                    className={classnames(
                      "result-list__item",
                      cursor === idx ? "active" : null
                    )}
                    key={el.Id}
                    tabIndex={idx}
                    onClick={() => selectHandler(el)}
                  >
                    <ParagraphText primary>{el.Text},</ParagraphText>
                    <ParagraphText>{el.Description}</ParagraphText>
                  </div>
                ))
              ) : (
                <div className="result-list__item">
                  <ParagraphText>{consts.NO_RESULTS}</ParagraphText>
                </div>
              )}
            </div>
          </Fragment>
        )}
        {(selectedLocation || standalonePicker) && (
          <div className="address-area">
            <p>{consts.ADDRESS}</p>
            <AddressField
              addressText={location ? location.address : ""}
              onChangeHandler={manualLocationHandler}
              hasError={hasError}
              errorMessage={error}
            />
          </div>
        )}
        {!hideButtons && (
          <div className="address-actions">
            <Button primary ghost action={() => onCancel()} content="Close" />
            <Button
              primary
              disabled={!selectedLocation || hasError}
              content={confirmText}
              action={() => onConfirmHandle()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressPicker;
