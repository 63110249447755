import React, { useContext } from "react";
import propTypes from "prop-types";
import classnames from "classnames";
import Timeline from "../Timeline";
import Panel from "../Panel";
import HeaderText from "../HeaderText";
import ParagraphText from "../ParagraphText";
import Icon from "../Icon";
import { useMediaQuery } from "react-responsive";
import { ThemeContext } from "styled-components";
import breakpoints from "../../theme/breakpoints";
import { COLLECTION_LINK_DISCLAIMER_MESSAGE } from "./const.js"
const LogisticsStatus = ({ className = "logistics-status", qaClassName = "qa-logistics-status", headerText, subHeaderText, data, issueMsg, footerActions, collectionBookingPortalLink }) => {
  const theme = useContext(ThemeContext);
  const isLargeDesktop = useMediaQuery({
    query: `(min-width: ${breakpoints.LARGE_DESKTOP})`,
  });
  
  return (
    <div className={classnames(className, qaClassName)}>
      <Panel flex="1" padding={isLargeDesktop ? "NONE" : "REGULAR"}>
        <HeaderText>{headerText}</HeaderText>
        <ParagraphText>{subHeaderText}</ParagraphText>
        {collectionBookingPortalLink && (
          <div className="collection-link-div">
            <ParagraphText>{COLLECTION_LINK_DISCLAIMER_MESSAGE}</ParagraphText>
          </div>
        )}
      </Panel>
      <div className="logitics-status-timeline-wrapper">
        <Panel flex="1" padding="NONE">
          <Timeline
            configuration={{ dividerSize: !isLargeDesktop ? 7 : 15.5 }}
            data={data}
            vertical={!isLargeDesktop}
          ></Timeline>
        </Panel>
      </div>
      {issueMsg && (
        <Panel flex="1">
          <div className="warning-message">
            <Icon
              type="alert-triangle"
              width="40"
              height="40"
              strokeColour={theme.COLOURS.ORANGE.base}
            />
            <ParagraphText>{issueMsg}</ParagraphText>
          </div>
        </Panel>
      )}
      <Panel
        flex="1"
        padding={isLargeDesktop ? "NONE" : "REGULAR"}
        actions={footerActions}
      />
    </div>
  );
};

LogisticsStatus.propTypes = {
  className: propTypes.string,
  qaClassName: propTypes.string,
  headerText: propTypes.string,
  subHeaderText: propTypes.string,
  data: propTypes.array,
  issueMsg: propTypes.string,
  onClose: propTypes.func,
};

export default LogisticsStatus;
