import React from "react";
import propTypes from "prop-types";
import ParagraphText from "../ParagraphText";

const Tooltip = (props) => {
  const {
    className = "tooltip",
    show = true,
    maxCharacters = 500,
    children,
    content,
  } = props;
  let truncatedContent = content;

  if (truncatedContent?.length > maxCharacters) {
    truncatedContent = truncatedContent.substring(0, maxCharacters) + "...";
  }
  return (
    <div className={className}>
      {children}
      {show && (
        <ParagraphText primary small className="tooltiptext">
          {truncatedContent}
        </ParagraphText>
      )}
    </div>
  );
};

Tooltip.propTypes = {
  className: propTypes.string,
  content: propTypes.string,
  show: propTypes.bool,
};

export default Tooltip;
