import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const AddNote = ({fill, width = "33", height = "30" }) => {
  const theme = useContext(ThemeContext);
  const fillColour = fill ? fill : theme.COLOURS.ACCENT.base;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1"
      viewBox="0 0 360.29 366.41"
    >
      <defs>
        <clipPath id="clip-path" transform="translate(0 -.55)">
          <path fill="none" d="M0 0H360.96V367.92H0z" />
        </clipPath>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill={fillColour}
            d="M243 341.14a2.25 2.25 0 01-2.25 2.25h-215a2.26 2.26 0 01-2.25-2.25V26.37a2.26 2.26 0 012.25-2.25h215a2.25 2.25 0 012.25 2.25v58.15l23.28-24.38.28-34.57a25.86 25.86 0 00-25.81-25h-215A25.85 25.85 0 000 26.37v314.77A25.86 25.86 0 0025.82 367h215a25.86 25.86 0 0025.83-25.82V194.5L243 214z"
            transform="translate(0 -.55)"
          />
          <path
            fill={fillColour}
            d="M41.19 277A11.79 11.79 0 0053 288.74h41.2a35.22 35.22 0 01-5.06-23.57H53A11.78 11.78 0 0041.19 277zM101.84 206H53a11.79 11.79 0 100 23.57h43.74zM144.49 143.85H53a11.79 11.79 0 100 23.58h67.94z"
            transform="translate(0 -.55)"
          />
          <g clipPath="url(#clip-path)">
            <path
              fill={fillColour}
              d="M203.65 87.69H53a11.79 11.79 0 000 23.58h127.07zM346.38 48.79a49 49 0 00-70-.53l-146 146a11.81 11.81 0 00-3.18 5.84l-14.6 67.39a11.78 11.78 0 0014 14l67.4-14.6a11.94 11.94 0 005.84-3.19l146-146a49 49 0 00.5-68.94zm-206.82 205.8l6.15-28.39L168 248.44zm189.65-153.53L191.56 238.71l-36.12-36.12L293.09 64.93a25.54 25.54 0 1136.12 36.13z"
              transform="translate(0 -.55)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

AddNote.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string
};

export default AddNote;
