import React, { Fragment, useState, useEffect, useContext } from "react";
import { ThemeContext } from "styled-components";
import propTypes from "prop-types";
import classnames from "classnames";
import InputLabel from "../InputLabel";
import Icon from "../Icon";

const Accordion = ({
  label = "Show Me",
  isOpened = false,
  labelCount = 0,
  action,
  children,
  qaClassName,
  className,
  labelDetail,
  isDisabled,
  iconColour
}) => {
  const theme = useContext(ThemeContext);

  const [opened, setOpened] = useState(false);
  let iconType = opened ? "chevron-up" : "chevron-down";

  const toggle = () => {
    if (!isDisabled) {
      setOpened(!opened);
    }

    if (action && !isDisabled) {
      return action();
    }
  };

  /**
   * We can use the isOpened prop (which may change over time) to
   * also drive the opened state (as well as the toggle() function)
   */
  useEffect(() => {
    if (isOpened) {
      setOpened(true);
    }
    if (!isOpened) {
      setOpened(false);
    }
  }, [isOpened]);

  const labelCountElement = !!labelCount && (
    <span className="accordion__header-count">{labelCount}</span>
  );

  return (
    <div className={classnames(className, qaClassName)}>
      <div className="accordion__header" onClick={() => toggle()}>
        <div className="accordion__header-label">
          {!labelDetail && labelCountElement}
          <InputLabel navigationPane>{label}</InputLabel>
          {labelDetail && (
            <Fragment>
              {labelCountElement}
              <span className="accordion__header-detail">
                <span>{labelDetail}</span>
              </span>
            </Fragment>
          )}
        </div>
        <div className="accordion__header-chevron">
          <Icon
            type={iconType}
            width="15"
            height="15"
            strokeColour={iconColour || theme.COLOURS.ACCENT.base}
            strokeWidth="3"
          />
        </div>
      </div>
      {opened && <div className="accordion__content">{children}</div>}
    </div>
  );
};

Accordion.propTypes = {
  children: propTypes.node,
  isOpened: propTypes.bool,
  label: propTypes.oneOfType([propTypes.string, propTypes.object]),
  className: propTypes.string,
  qaClassName: propTypes.string,
  labelCount: propTypes.number,
};

export default Accordion;
