import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const VanFromTop = ({
  width = "150",
  height = "300",
  strokeWidth = "2",
  strokeColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.shades[60];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      enableBackground="new 0 0 150 300"
      version="1.1"
      viewBox="0 0 150 300"
    >
      <defs>
        <style>
          {`.st0{fill:none;stroke:${stroke};stroke-width:${strokeWidth};stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
	.st1{fill:none;stroke:${stroke};stroke-width:${strokeWidth};stroke-miterlimit:10;}
	.st2{fill:none;stroke:${stroke};stroke-width:${strokeWidth};stroke-linecap:round;stroke-miterlimit:10;}`}
        </style>
      </defs>
      <g>
        <g>
          <path
            className="st0"
            d="M44.7,5.6v11.2h59.1V5.6C85.8,1.2,62.6,1.2,44.7,5.6z"
          />
        </g>
        <path
          className="st1"
          d="M121.9,294.9c0,0.5-1.3,1-3,1.2l-13.7,1.2c-1.7,0.1-3.1-0.1-3.1-0.7l-0.3-2.1c0-0.5,1.3-1,3-1.2l13.7-1.2
		c1.7-0.1,3.1,0.1,3.2,0.7L121.9,294.9z"
        />
        <path
          className="st1"
          d="M46.1,296.8c0,0.5-1.5,0.8-3.1,0.7l-13.7-1c-1.7-0.1-3-0.6-3-1.2l0.2-2.1c0-0.5,1.4-0.8,3.1-0.7l13.7,1
		c1.7,0.1,3,0.6,3,1.1L46.1,296.8z"
        />
        <path
          className="st1"
          d="M15.2,130.2c0,2.8,3.8,19.5,3.2,41.1c-1.4,46.1-3,114.5,1,118.4c7.1,6.8,104.8,5.7,109.6,0
		c3.6-4.2,2.2-72.4,0.9-118.4c-0.6-21.6,3.3-38.2,3.3-41.1c-0.2-12.1,4.2-67.7,0.5-99c-2.4-20.4-6.1-17.8-19.4-22.4
		C94.4,2,54.6,1.2,34.2,8.9c-14.5,5.4-17,2-19.4,22.4C11,62.5,15.4,118.1,15.2,130.2z"
        />
        <path
          className="st0"
          d="M49.2,92.5c11.7-1.1,38.4-1.1,50.1,0c10.6,1,18.3,3.1,21.1,3.9l6.8-27c-0.6-0.5-1.7-0.9-3.5-1.4
		c-4.1-1.2-11.3-3.3-22.6-5c-16.1-2.4-37.6-2.4-53.7,0c-11.2,1.7-18.4,3.8-22.6,5c-1.8,0.5-3,1-3.5,1.4l6.8,27
		C30.9,95.6,38.5,93.6,49.2,92.5z"
        />
        <path
          className="st0"
          d="M19.3,135.2c0,8.9,1.1,14.9,1.5,18l6.3,2.7h0.5l-1.5-38.1l-8.6-45.4c0,0-0.7,11.6,0,28.3
		C18,111.3,19.3,125.9,19.3,135.2z"
        />
        <path
          className="st0"
          d="M121.3,156l7-2.7c0.2-3,0.8-9.1,0.8-18c0-9.4,1.3-23.9,1.8-34.4c0.7-16.7,0-28.3,0-28.3l-8.6,45.4l-1.5,38.1
		H121.3z"
        />
        <g>
          <path
            className="st1"
            d="M104.1,181.1c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v78.8c0,0.9-0.7,1.6-1.6,1.6l0,0
			c-0.9,0-1.6-0.7-1.6-1.6V181.1z"
          />
          <path
            className="st1"
            d="M97.8,181.1c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v78.8c0,0.9-0.7,1.6-1.6,1.6l0,0
			c-0.9,0-1.6-0.7-1.6-1.6V181.1z"
          />
          <path
            className="st1"
            d="M91.4,181.1c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v78.8c0,0.9-0.7,1.6-1.6,1.6l0,0
			c-0.9,0-1.6-0.7-1.6-1.6V181.1z"
          />
          <path
            className="st1"
            d="M53.6,181.1c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v78.8c0,0.9-0.7,1.6-1.6,1.6l0,0
			c-0.9,0-1.6-0.7-1.6-1.6V181.1z"
          />
          <path
            className="st1"
            d="M47.3,181.1c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v78.8c0,0.9-0.7,1.6-1.6,1.6l0,0
			c-0.9,0-1.6-0.7-1.6-1.6V181.1z"
          />
          <path
            className="st1"
            d="M41,181.1c0-0.9,0.7-1.6,1.6-1.6l0,0c0.9,0,1.6,0.7,1.6,1.6v78.8c0,0.9-0.7,1.6-1.6,1.6l0,0
			c-0.9,0-1.6-0.7-1.6-1.6V181.1z"
          />
        </g>
        <path
          className="st0"
          d="M40,12l-14.3,4.5c0,0-3.9,2-4.9,5s-2,7.9-2,7.9s7,1.9,11-2.2S40,12,40,12z"
        />
        <path
          className="st0"
          d="M108.6,12.2l14.3,4.5c0,0,3.9,2,4.9,5c1,2.9,2,7.9,2,7.9s-7,1.9-11-2.2C114.7,23.4,108.6,12.2,108.6,12.2z"
        />
        <line className="st2" x1="117.4" y1="98.7" x2="117.4" y2="284.8" />
        <line className="st2" x1="29.8" y1="98.7" x2="29.8" y2="284.8" />
      </g>
    </svg>
  );
};

VanFromTop.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default VanFromTop;
