import styled, { css } from "styled-components";
import Timeline from "./Timeline";

const timelineHorizontal = css`
  padding: 2rem 0 8rem 0;

  // hiding the first divider and dragging the timeline to the left ensuring we leave enough space for text
  .entry:first-child .divider {
    visibility: hidden;
    margin-left: ${(props) =>
      `-${(props.configuration?.dividerSize ?? 15) / 2}rem`};
  }

  // ensuring we leave space for the text
  .entry:last-child .entry-bullet {
    margin-right: ${(props) =>
      `${(props.configuration?.dividerSize ?? 15) / 2}rem`};
  }

  .entry {
    .divider {
      width: ${(props) => `${props.configuration?.dividerSize ?? 15}rem`};
      height: 0.3rem;
    }

    .entry-text {
      position: absolute;
      top: 120%;
      left: 50%;
      transform: translate(-50%);
    }
  }
`;

const timelineVertical = css`
  flex-direction: column;
  padding: 2rem;

  // removing the first divider
  .entry:first-child .divider {
    display: none;
  }

  .entry {
    flex-direction: column;

    .divider {
      height: ${(props) => `${props.configuration?.dividerSize ?? 15}rem`};
      width: 0.3rem;
      margin: 1rem;
    }

    .entry-text {
      > p {
        width: 100%;
      }
    }
  }
`;

const StyledTimeline = styled(Timeline)`
  display: flex;
  background-color: ${(props) => props.theme.COLOURS.WHITE};

  .entry {
    display: flex;
    align-items: center;

    .divider {
      border: none;
    }

    .entry-bullet {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5rem;
      width: 5rem;
      border-style: solid;
      border-width: 0.3rem;
      border-radius: 50%;
      background-color: transparent;
      margin: 0 0.5rem 0 0.5rem;

      > span {
        display: inline-flex;
      }
    }

    .entry-text {
      text-align: center;

      > p {
        width: ${(props) =>
          `${(props.configuration?.dividerSize ?? 15) / 0.8}rem`};
      }

      > p:first-child {
        font-weight: ${(props) => props.theme.FONTS.weights[70]};
      }
    }
  }

  ${(props) => (props.vertical ? timelineVertical : timelineHorizontal)};
`;

export default StyledTimeline;
