import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const AlertCircle = ({
  strokeColour,
  width = "32",
  height = "32",
  strokeWidth = "2",
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M30.667 15.748c.02 8.15-6.518 14.8-14.667 14.919A14.45 14.45 0 011.333 16.256C1.311 8.105 7.85 1.452 16 1.333a14.45 14.45 0 0114.667 14.415zM16 18.673V9.34" />
        <path d="M15.988 21.34a.327.327 0 00-.321.34.339.339 0 00.337.327h0a.328.328 0 00.321-.34.337.337 0 00-.325-.327h-.007" />
      </g>
    </svg>
  );
};

AlertCircle.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default AlertCircle;
