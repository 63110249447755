import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Phone = ({
  width = "25",
  height = "25",
  strokeWidth = "2",
  strokeColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={strokeWidth}
      className="feather feather-phone"
      viewBox="0 0 24 24"
    >
      <path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"></path>
    </svg>
  );
};

Phone.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default Phone;
