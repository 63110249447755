import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import SubHeaderText from "../SubHeaderText";
import KeyInformationLabel from "../KeyInformationLabel";
import PriceBreakdown from "../PriceBreakdown";
import PriceText from "../PriceText";
import { getVatStatusDisplayValue } from "../../../shared/utility";
import { ThemeContext } from "styled-components";

const SummaryPriceBreakdown = ({
  className,
  labels = {
    priceHeading: "Price breakdown",
    priceLabel: "Vehicle Price",
    adminChargeLabel: "Admin Charge",
    deliveryCostLabel: "Delivery Cost",
    netLabel: "Net",
    totalLabel: "Total",
    vatLabel: "VAT",
    totalCostLabel: "Total cost",
  },
  data,
  inOrderSummary,
  collapsable,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <div className={className}>
      {collapsable ? (
        <Fragment>
          <KeyInformationLabel>{labels.netLabel}</KeyInformationLabel>
          <dl>
            <dt>
              <PriceBreakdown>
                {labels.priceLabel}
                <KeyInformationLabel small>
                  {`(VAT ${getVatStatusDisplayValue(
                    data.vehicle.vatQualified
                  )})`}
                </KeyInformationLabel>
              </PriceBreakdown>
            </dt>
            <dd>
              <PriceText
                className="vehicle-payment-summary__price"
                tiny
                content={data.vehicle.net}
              />
            </dd>
          </dl>
          <dl>
            <dt>
              <PriceBreakdown>{labels.adminChargeLabel}</PriceBreakdown>
            </dt>
            <dd>
              <PriceText
                className="vehicle-payment-summary__price"
                tiny
                content={data.adminCharge.net}
              />
            </dd>
          </dl>
          <dl>
            <dt>
              <PriceBreakdown>{labels.deliveryCostLabel}</PriceBreakdown>
            </dt>
            <dd>
              <PriceText
                className="vehicle-payment-summary__price"
                tiny
                content={data.deliveryCost.net}
              />
            </dd>
          </dl>
          <br />

          <KeyInformationLabel>{labels.vatLabel}</KeyInformationLabel>
          <dl>
            <dt>
              <PriceBreakdown>
                {labels.priceLabel}
                <KeyInformationLabel small>
                  {`(VAT ${getVatStatusDisplayValue(
                    data.vehicle.vatQualified
                  )})`}
                </KeyInformationLabel>
              </PriceBreakdown>
            </dt>
            <dd>
              <PriceText
                className="vehicle-payment-summary__price"
                tiny
                content={data.vehicle.vat}
              />
            </dd>
          </dl>
          <dl>
            <dt>
              <PriceBreakdown>{labels.adminChargeLabel}</PriceBreakdown>
            </dt>
            <dd>
              <PriceText
                className="vehicle-payment-summary__price"
                tiny
                content={data.adminCharge.vat}
              />
            </dd>
          </dl>
          <dl>
            <dt>
              <PriceBreakdown>{labels.deliveryCostLabel}</PriceBreakdown>
            </dt>
            <dd>
              <PriceText
                className="vehicle-payment-summary__price"
                tiny
                content={data.deliveryCost.vat}
              />
            </dd>
          </dl>
          <br />

          <KeyInformationLabel>{labels.totalLabel}</KeyInformationLabel>
          <dl>
            <dt>
              <PriceBreakdown>
                {labels.priceLabel}
                <KeyInformationLabel small>
                  {`(VAT ${getVatStatusDisplayValue(
                    data.vehicle.vatQualified
                  )})`}
                </KeyInformationLabel>
              </PriceBreakdown>
            </dt>
            <dd>
              <PriceText
                className="vehicle-payment-summary__price"
                tiny
                content={data.vehicle.gross}
              />
            </dd>
          </dl>
          <dl>
            <dt>
              <PriceBreakdown>{labels.adminChargeLabel}</PriceBreakdown>
            </dt>
            <dd>
              <PriceText
                className="vehicle-payment-summary__price"
                tiny
                content={data.adminCharge.gross}
              />
            </dd>
          </dl>
          <dl>
            <dt>
              <PriceBreakdown>{labels.deliveryCostLabel}</PriceBreakdown>
            </dt>
            <dd>
              <PriceText
                className="vehicle-payment-summary__price"
                tiny
                content={data.deliveryCost.gross}
              />
            </dd>
          </dl>
          <dl>
            <dt colSpan="3">
              <PriceBreakdown
                className="vehicle-payment-summary__label--bold"
                light
              >
                {labels.totalCostLabel}
              </PriceBreakdown>
            </dt>
            <dd>
              <PriceText
                className="vehicle-payment-summary__price"
                tiny
                content={data.total.gross}
                priceColor={theme.COLOURS.PRIMARY.base}
              />
            </dd>
          </dl>
        </Fragment>
      ) : (
        <table>
          <thead>
            <tr>
              <th>
                <SubHeaderText
                  small
                  className={classnames("vehicle-payment-summary__subheading", {
                    "vehicle-payment-summary__subheading--grey": inOrderSummary,
                  })}
                >
                  {labels.priceHeading}
                </SubHeaderText>
              </th>
              <th>
                <KeyInformationLabel>{labels.netLabel}</KeyInformationLabel>
              </th>
              <th>
                <KeyInformationLabel>{labels.vatLabel}</KeyInformationLabel>
              </th>
              <th>
                <KeyInformationLabel>{labels.totalLabel}</KeyInformationLabel>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <PriceBreakdown>{labels.priceLabel}</PriceBreakdown>
                <div className="vat-status">
                  <KeyInformationLabel small>
                    {`(VAT ${getVatStatusDisplayValue(
                      data.vehicle.vatQualified
                    )})`}
                  </KeyInformationLabel>
                </div>
              </td>
              <td>
                <PriceText
                  className="vehicle-payment-summary__price"
                  tiny
                  content={data.vehicle.net}
                />
              </td>
              <td>
                <PriceText
                  className="vehicle-payment-summary__price"
                  tiny
                  content={data.vehicle.vat}
                />
              </td>
              <td>
                <PriceText
                  className="vehicle-payment-summary__price"
                  tiny
                  content={data.vehicle.gross}
                />
              </td>
            </tr>
            <tr>
              <td>
                <PriceBreakdown>{labels.adminChargeLabel}</PriceBreakdown>
              </td>
              <td>
                <PriceText
                  className="vehicle-payment-summary__price"
                  tiny
                  content={data.adminCharge.net}
                />
              </td>
              <td>
                <PriceText
                  className="vehicle-payment-summary__price"
                  tiny
                  content={data.adminCharge.vat}
                />
              </td>
              <td>
                <PriceText
                  className="vehicle-payment-summary__price"
                  tiny
                  content={data.adminCharge.gross}
                />
              </td>
            </tr>
            <tr>
              <td>
                <PriceBreakdown>{labels.deliveryCostLabel}</PriceBreakdown>
              </td>
              <td>
                <PriceText
                  className="vehicle-payment-summary__price"
                  tiny
                  content={data.deliveryCost.net}
                />
              </td>
              <td>
                <PriceText
                  className="vehicle-payment-summary__price"
                  tiny
                  content={data.deliveryCost.vat}
                />
              </td>
              <td>
                <PriceText
                  className="vehicle-payment-summary__price"
                  tiny
                  content={data.deliveryCost.gross}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                <PriceBreakdown
                  className="vehicle-payment-summary__label--bold"
                  light
                >
                  {labels.totalCostLabel}
                </PriceBreakdown>
              </td>
              <td>
                <PriceText
                  className="vehicle-payment-summary__price"
                  tiny
                  content={data.total.gross}
                  priceColor={theme.COLOURS.PRIMARY.base}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

const pricingShape = PropTypes.shape({
  net: PropTypes.number,
  vat: PropTypes.number,
  gross: PropTypes.number,
  vatCode: PropTypes.oneOf(["OutOfScope", "Exempt", "Standard", "Zero"]),
  vatQualified: PropTypes.oneOf([
    "Unknown",
    "Export",
    "Commercial",
    "Qualified",
    "NonQual",
    "NonQualified",
  ]),
});

SummaryPriceBreakdown.propTypes = {
  className: PropTypes.string,
  data: PropTypes.shape({
    vehicleBlobUrl: PropTypes.string,
    vehicleId: PropTypes.number,
    make: PropTypes.string,
    range: PropTypes.string,
    model: PropTypes.string,
    derivative: PropTypes.string,
    vehicle: pricingShape,
    adminCharge: pricingShape,
    deliveryCost: pricingShape,
    total: pricingShape,
  }),
  labels: PropTypes.shape({
    priceHeading: PropTypes.string,
    priceLabel: PropTypes.string,
    adminChargeLabel: PropTypes.string,
    deliveryCostLabel: PropTypes.string,
    netLabel: PropTypes.string,
    totalLabel: PropTypes.string,
    vatLabel: PropTypes.string,
    totalCostLabel: PropTypes.string,
  }),
  inOrderSummary: PropTypes.bool,
};

export default SummaryPriceBreakdown;
