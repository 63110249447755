import React from "react";
import PropTypes from "prop-types";
import CapVehicleLookupTableRow from "./child-components/CapVehicleLookupTableRow";
import CapVehicleLookupTableHeader from "./child-components/CapVehicleLookupTableHeader";
import SkeletonRows from "../../../views/components/Skeletons/SkeletonRows";

const CapVehicleLookupTable = ({
  className,
  capVehicleData,
  isFetchingVehicleLookupData,
  handleRowSelection,
  selectedRow,
}) => {
  return (
    <div className={className}>
      <div className="capTable-content">
        <CapVehicleLookupTableHeader />
        <div className="row-container">
          {isFetchingVehicleLookupData ? (
            <div className="capTable-skeleton">
              <SkeletonRows nrOfRows={2} />
            </div>
          ) : (
            <table>
                {capVehicleData &&
                  capVehicleData.map((el, index) => (
                    <CapVehicleLookupTableRow
                      key={index}
                      capVehicleLookupData={el}
                      isSelected={selectedRow === el}
                      onSelectRow={() => handleRowSelection(el)}
                    />
                  ))}
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

CapVehicleLookupTable.propTypes = {
  className: PropTypes.string,
  capVehicleData: PropTypes.array,
  isFetchingVehicleLookupData: PropTypes.bool.isRequired,
};

export default CapVehicleLookupTable;
