import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Star = ({
  width = "33",
  height = "30",
  strokeWidth = "1.938",
  strokeColour,
  fillColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  const fill = fillColour ? theme.COLOURS.ACCENT.base : "none";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 24.25l-9.11 4.79 1.74-10.145-7.371-7.185 10.186-1.48L16.5 1l4.555 9.23 10.186 1.48-7.37 7.185 1.74 10.145z"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill={fill}
        fillRule="evenodd"
        strokeLinejoin="round"
      />
    </svg>
  );
};

Star.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
  fillColour: PropTypes.bool,
};

export default Star;
