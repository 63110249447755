import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  Fragment,
} from "react";
import { ThemeContext } from "styled-components";
import Icon from "./../Icon";
import classnames from "classnames";
import SettingsMenuOptions from "./../SettingsMenuOptions";
import PropTypes from "prop-types";

const SettingsMenu = ({
  className,
  qaClassName = "qa-settings-menu",
  onStockListDownload,
  onAboutSession,
  onLogout,
  isAccountAdministrator,
  inline,
  buyerOnly,
  allowPushNotifications,
}) => {
  const [show, setShow] = useState(false);
  const node = useRef();

  const openSettingsMenu = () => {
    if (!inline) setShow(!show);
  };

  useEffect(() => {
    if (inline) setShow(true);
  }, [inline]);

  useEffect(() => {
    const closePopover = (e) => {
      if (!node.current?.contains(e.target) && !inline) {
        setShow(false);
      }
    };
    document.addEventListener("click", closePopover);
    return () => {
      document.removeEventListener("click", closePopover);
    };
  }, [inline]);

  const theme = useContext(ThemeContext);

  return (
    <div
      onClick={openSettingsMenu}
      className={classnames(className, qaClassName, show ? "active" : null)}
      ref={node}
    >
      {!inline && (
        <Icon
          className={classnames("settings-menu-icon", "qa-settings-menu-icon")}
          type="settings"
          strokeColour={theme.COLOURS.WHITE}
          strokeWidth="3"
          width="28"
          height="28"
        />
      )}
      <div
        onClick={(e) => e.stopPropagation()}
        className={classnames("popover", "qa-settings-menu-popover")}
      >
        {show && (
          <Fragment>
            <div className="popover__arrow" />
            <div className="popover__container">
              <div className="popover__container__panel">
                <SettingsMenuOptions
                  onAboutSession={onAboutSession}
                  onStockListDownload={onStockListDownload}
                  onLogout={onLogout}
                  isAccountAdministrator={isAccountAdministrator}
                  inline={inline}
                  buyerOnly={buyerOnly}
                  allowPushNotifications={allowPushNotifications}
                />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

SettingsMenu.propTypes = {
  className: PropTypes.string,
  qaClassName: PropTypes.string,
  onStockListDownload: PropTypes.func,
  onAboutSession: PropTypes.func,
  onLogout: PropTypes.func,
  isAccountAdministrator: PropTypes.bool,
};

export default SettingsMenu;
