import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useIsLoggedInUserType from "./../../../shared/hooks/useIsLoggedInUserType";
import { useSelector } from "react-redux";
import LoadingTransition from "./../../components/LoadingTransition";
import { USER_TYPE_MAP } from "../../../shared/utility";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const isFetchingCredentials = useSelector((state) => state.authentication.isFetching);
  let isLoggedInBuyer = useIsLoggedInUserType(USER_TYPE_MAP.Buyer);
  let isLoggedInVendor = useIsLoggedInUserType(USER_TYPE_MAP.Vendor);
  if (isFetchingCredentials) return <LoadingTransition />;
 
  return isLoggedInBuyer ? (
      <Component {...rest} />
    ) : !isLoggedInVendor && (
      <Navigate to="/login" state={{ from: location }} />
    );
};

export default PrivateRoute;
