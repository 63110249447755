import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const EqualsCircle = ({
  width = "34",
  height = "34",
  strokeWidth = "2",
  strokeColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="16" cy="16.452" r="16" />
        <line
          x1="10"
          y1="15.452"
          x2="22"
          y2="15.452"
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
        <line
          x1="10"
          y1="19.452"
          x2="22"
          y2="19.452"
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
};

EqualsCircle.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default EqualsCircle;
