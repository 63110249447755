import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const AlertTriangle = ({
  strokeColour,
  width = "30",
  height = "30",
  strokeWidth = "2",
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M28.133 27.234a.708.708 0 01-.689 1.113H2.556a.708.708 0 01-.689-1.113L14.444 2.083c.306-.613.806-.613 1.112 0l12.577 25.151zM15 20.263v-8.711" />
        <path d="M14.989 22.752a.306.306 0 00-.3.317c.007.17.146.304.316.305h.006a.306.306 0 00.3-.317.317.317 0 00-.311-.305h-.006" />
      </g>
    </svg>
  );
};

AlertTriangle.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default AlertTriangle;
