import React from "react";
import PropTypes from "prop-types";
import COLOURS from "../../../theme/base/colours";

const Edit = ({
  width = "24",
  height = "24",
  strokeColour = COLOURS.PRIMARY.base,
  strokeWidth = "2",
}) => (
  <svg
    xmlns="
http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    stroke={strokeColour}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={strokeWidth}
    viewBox="0 0 24 24"
  >
    <path d="M11 4H4a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7"></path>
    <path d="M18.5 2.5a2.121 2.121 0 013 3L12 15l-4 1 1-4 9.5-9.5z"></path>
  </svg>
);

Edit.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default Edit;
