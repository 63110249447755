import styled from "styled-components";
import LogisticsStatus from "./LogisticsStatus";
import breakpoints from "../../theme/breakpoints";

const StyledLogisticsStatus = styled(LogisticsStatus)`
  background-color: ${(props) => props.theme.COLOURS.WHITE};
  height: 100%;

  @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
    box-sizing: border-box;
    border: 0.2rem solid ${(props) => props.theme.COLOURS.ACCENT.base};
    padding: ${(props) => props.theme.SPACING.MEDIUM};
    border-radius: 0.9rem;
    width: 100%;
    height: auto;
  }

  .logitics-status-timeline-wrapper {
    @media (min-width: ${breakpoints.LARGE_DESKTOP}) {
      margin-top: ${(props) => props.theme.SPACING.REGULAR};
    }
  }

  .warning-message {
    display: flex;
    align-items: center;
    > p {
      margin-left: ${(props) => props.theme.SPACING.SMALL};
      color: ${(props) => props.theme.COLOURS.ORANGE.base};
    }
  }

  .collection-link-div {
    display: flex;
    
    button {
      margin: 0;
    }
  }
`;

export default StyledLogisticsStatus;
