import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useIsLoggedInUserType from "../../../shared/hooks/useIsLoggedInUserType";
import { useSelector } from "react-redux";
import LoadingTransition from "../../components/LoadingTransition/index";
import { USER_TYPE_MAP } from "../../../shared/utility";

const VendorRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const isFetchingCredentials = useSelector((state) => state.authentication.isFetching);
  let isLoggedInVendor = useIsLoggedInUserType(USER_TYPE_MAP.Vendor);
  let isLoggedInBuyer = useIsLoggedInUserType(USER_TYPE_MAP.Buyer);
  if (isFetchingCredentials) return <LoadingTransition />;

return isLoggedInVendor ? (
    <Component {...rest} />
  ) : !isLoggedInBuyer && (
    <Navigate to="/login" state={{ from: location }} />
  );
};

export default VendorRoute;
