import React, { useState } from "react";
import propTypes from "prop-types";
import ParagraphText from "../ParagraphText";

const TextArea = ({
  className,
  validValue,
  maximumCharactersTop,
  maximumCharactersBottom,
  value = "",
  onChange,
  rows = 7, 
  cols = 50, 
  ...rest
}) => {
  const [text, setText] = useState(value);

  const maximumCharacters = maximumCharactersTop || maximumCharactersBottom;

  const onTextChange = (value) => {
    if (maximumCharacters) {
      if (value.length <= maximumCharacters) {
        setText(value);
      }
    } else {
      setText(value);
    }
  };

  return (
    <div className={className}>
      <div className="text-area__input-wrapper">
        {maximumCharactersTop && (
          <div className="text-area__character-counter">
            <ParagraphText>{`Character limit ${
              text?.length || 0
            }/${maximumCharactersTop}`}</ParagraphText>
          </div>
        )}
        <textarea
          className={`text-area__input ${validValue ? "valid" : "invalid"}`}
          value={text}
          rows={rows}
          cols={cols}
          onChange={(e) => {
            onTextChange(e.target.value);
            onChange(e);
          }}
          onPaste={(e) => {
            const paste = e.clipboardData.getData("text");
            const newValue = text + paste;
            if (maximumCharacters && newValue.length > maximumCharacters) {
              e.preventDefault();
              setText(newValue.slice(0, maximumCharacters));
            } else {
              e.preventDefault();
              setText(newValue);
            }
            onChange({ ...e, target: { ...e.target, value: newValue } });
          }}
          type="text"
          {...rest}
        />
      </div>
      {maximumCharactersBottom && (
        <div className="text-area__character-counter">
          <ParagraphText>{`Character limit ${
            text?.length || 0
          }/${maximumCharactersBottom}`}</ParagraphText>
        </div>
      )}
    </div>
  );
};

TextArea.propTypes = {
  className: propTypes.string,
  validValue: propTypes.bool,
  maximumCharactersTop: propTypes.number,
  maximumCharactersBottom: propTypes.number,
  onChange: propTypes.func,
  value: propTypes.string,
};

export default TextArea;
