import React from "react";
import PropTypes from "prop-types";

import Header from "../../Header";
import Navigation from "../../../components/Navigation";

const SkeletonDefaultPageVendor = ({ className, hasNav = true }) => {
  return (
    <div className={className}>
      <Header>
        {hasNav && <Navigation />}
        <div className="skeleton__page-heading">
          <span className="skeleton__page-title" />
          <span className="skeleton__page-user" />
        </div>
      </Header>
      <div className="skeleton__page-content" />
    </div>
  );
};

SkeletonDefaultPageVendor.propTypes = {
  className: PropTypes.string,
  hasNav: PropTypes.node,
};

export default SkeletonDefaultPageVendor;
