import React from "react";
import colours from "../../../views/theme/base/colours";
import Countdown from "react-countdown";
import dayjs from "dayjs";
import Tooltip from "../../../views/components/Tooltip";
import PropTypes from "prop-types";

const CountdownInsert = ({ endDateTime, preText = "Bidding Ends", comingSoon = false }) => {
  let counterRemaining = "";
  let counterColour = "";
  let end = "";

  const endDateFormat = dayjs(endDateTime).format();

  if (endDateTime) {
    end = dayjs(endDateTime).format("Do MMM HH:mm");
  }

  const countdown = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>0s</span>;
    } else if (days > 0) {
      // Greater than 1 day
      return (
        <span>
          {preText} {end}
        </span>
      );
    } else if (days > 0) {
      // Greater than 1 day
      return <span>{end}</span>;
    } else {
      if (hours > 0) {
        // Less than a day, more than 1 hour
        counterRemaining = `${hours}h ${minutes}min`;
        counterColour = comingSoon ? colours.GREEN.base : colours.ORANGE.base;
      }
      if (hours <= 0 && minutes >= 1) {
        // Under 1 hour
        counterRemaining = `${minutes}min ${seconds}s`;
        counterColour = comingSoon ? colours.GREEN.base : colours.RED.base;
      }
      if (hours <= 0 && minutes <= 0) {
        // Under 1 min
        counterRemaining = `${seconds}s`;
        counterColour = comingSoon ? colours.GREEN.base : colours.RED.base;
      }
      return (
        <Tooltip direction={"top"} content={end}>
          <p>
            <span
              style={{ color: colours.PRIMARY.shades[60] }}
            >{`${preText} in `}</span>
            <span style={{ color: `${counterColour}` }}>
              {counterRemaining}
            </span>
          </p>
        </Tooltip>
      );
    }
  };

  return <Countdown date={endDateFormat} renderer={countdown} />;
};

CountdownInsert.prototype = {
  endDateTime: PropTypes.string,
  preText: PropTypes.string,
  comingSoon: PropTypes.bool,
};

export default CountdownInsert;
