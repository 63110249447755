import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Car4x4 = ({
  width = "44",
  height = "26",
  strokeWidth = "2",
  strokeColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 44 26"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M2.133 16.889v-5.333a6.42 6.42 0 01.916-3.304L7.467.89h10.666c2.264 0 4.42.96 5.935 2.643l5.62 6.246h6.223a4.555 4.555 0 014.418 3.45l.915 3.66a3.556 3.556 0 01-3.555 3.556H35.91M28.8 20.444h-16M32.356 16.889h8.888" />
        <circle cx="32.356" cy="20.444" r="3.556" />
        <path d="M7.467.889H3.911M29.689 9.778H2.384M12.8.889v8.889M9.244 16.889h-7.11a1.778 1.778 0 100 3.555h3.555" />
        <circle cx="9.244" cy="20.444" r="3.556" />
        <path d="M18.133 13.333h-1.777M4.8 13.333H2.133M40.356 13.333h-2.667a3.556 3.556 0 01-3.556-3.555" />
      </g>
    </svg>
  );
};

Car4x4.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default Car4x4;
