import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as consts from "./const";
import HeaderText from "../HeaderText";
import ParagraphText from "../ParagraphText";
import TextArea from "../TextArea";
import ModalInsert from "../ModalInsert";
import Panel from "../Panel";
import { closeModal } from "../../../state/reducers/global";
import {
  setActiveConfirmModalOptions,
  clearActiveGenericTextAreaModalOptions,
} from "../../../state/reducers/vendor-vehicle/genericActiveState";
import { addEventHistory } from "../../../state/reducers/vendor-vehicle/eventHistory";
import { vehicleMenuAction } from "../../../state/reducers/vendor-vehicle/vehicleDetails";

const GenericTextAreaModal = () => {
  const dispatch = useDispatch();

/* eslint-disable no-unused-vars */
const { type, data } = useSelector(
    (state) =>
      state.vendorVehicleRecord.genericActiveState
        .activeGenericTextAreaModalOptions
  );
/* eslint-enable no-unused-vars */

  const [textAreaValue, setTextAreaValue] = useState("");

  const disableCheck = textAreaValue.length === 0;

  const getText = (action) => {
    switch (action) {
      case "event-history":
        return {
          headerText: consts.EVENT_HISTORY.HEADER_TEXT,
          wordLimit: consts.EVENT_HISTORY.WORD_LIMIT,
        };
      case "workshop":
        return {
          headerText: consts.WORKSHOP.HEADER_TEXT,
          wordLimit: consts.WORKSHOP.WORD_LIMIT,
        };
      default:
        return {
          headerText: "",
          bodyText: "",
          wordLimit: 100,
        };
    }
  };

  const { headerText, bodyText, wordLimit } = getText(data?.action);

  const onCancel = () => {
    dispatch(closeModal());
    dispatch(clearActiveGenericTextAreaModalOptions());
  };

  const onClickSubmitData = () => {
    const actionData = {
      vehicleReference: data.vehicleReference,
      action: data.action,
    };
    dispatch(setActiveConfirmModalOptions(actionData.action, actionData));

    if (data.action === "event-history") {
      dispatch(addEventHistory(data.vehicleReference, textAreaValue));
    } else if (data.action === "workshop") {
      dispatch(vehicleMenuAction({actionType: data.action, vehicleId:  data.vehicleReference, actionData: textAreaValue}));
    }
  };

  function onChangeInput(e) {
    const { value } = e.target;
    setTextAreaValue(
      value.length <= wordLimit ? value : value.substring(0, wordLimit)
    );
  }

  return (
    <ModalInsert>
        <Panel
          mobileFlex
          style={{ height: "100%", borderRadius: "0.5rem" }}
          actions={[
            {
              content: "Close",
              action: onCancel,
              type: "ghost",
            },
            {
              content: "Save",
              action: onClickSubmitData,
              type: "primary",
              disabled: disableCheck,
            },
          ]}
        >
          <HeaderText>{headerText}</HeaderText>
          <ParagraphText>{bodyText}</ParagraphText>
          <TextArea
            value={textAreaValue}
            className="text-area__input"
            onChange={onChangeInput}
            validValue={true}
            resize="none"
            maximumCharactersBottom={wordLimit}
            rows="7"
            cols="80"
          ></TextArea>
        </Panel>
    </ModalInsert>
  );
};

export default GenericTextAreaModal;
