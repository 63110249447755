import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Wheel = ({ width = "35", height = "35", color }) => {
  const theme = useContext(ThemeContext);
  const fill = color ? color : theme.COLOURS.ACCENT.base;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 60 60"
      fill={fill}
    >
      <g>
        <path
          d="M30.1,1.1C14.2,1,1.1,13.9,1.1,29.9C1,45.8,14,58.9,29.9,58.9c15.9,0.1,29-12.9,29-28.8
		C59,14.2,46.1,1.1,30.1,1.1z M46.9,47.3L45,48.9c-0.5,0.4-1.1,0.8-1.7,1.2c-0.7,0.5-1.5,0.9-2.2,1.3c-3.4,1.8-7.2,2.7-11.1,2.7
		h-0.1c-4,0-7.7-1-11.2-2.8L16.6,50c-0.6-0.4-1.1-0.8-1.6-1.1l0,0l-0.2-0.1l-1.7-1.5L13,47.1c-4.6-4.6-7.2-10.7-7.1-17.2
		c0-1.4,0.1-2.7,0.4-4.1c0.1-0.9,0.3-1.7,0.6-2.5c0.2-0.5,0.3-1.1,0.5-1.6l0.1-0.3c0.4-1,0.7-1.7,1.1-2.4c3.5-7,10.2-11.8,18-12.9
		C27.4,6,28.2,5.9,29,5.9c0.3,0,0.6,0,0.8,0c0,0,0.2,0,0.3,0c0.4,0,0.7,0,1.1,0.1c0.6,0,1.4,0.1,2.4,0.2c7.7,1.2,14.5,6.1,18,13.1
		c0.5,0.9,0.8,1.7,1,2.4c0.3,0.6,0.5,1.3,0.6,1.9c0.3,0.9,0.5,1.8,0.6,2.5c0.2,1.2,0.3,2.6,0.3,4C54.1,36.6,51.6,42.7,46.9,47.3z"
        />
        <path
          d="M52.9,26.2c-0.1-0.8-0.3-1.6-0.5-2.4c-0.2-0.6-0.3-1.3-0.6-1.9c-0.3-0.8-0.6-1.5-1-2.2
		c-3.3-6.6-9.7-11.4-17.2-12.6c-0.8-0.1-1.6-0.2-2.4-0.2c-0.3,0-0.6-0.1-1-0.1c-0.4,0-0.7,0-1,0.1c-0.8,0-1.6,0.1-2.4,0.2
		c-7.6,1.1-14,5.9-17.3,12.4c-0.4,0.7-0.7,1.5-1,2.2c-0.2,0.6-0.4,1.2-0.6,1.8c-0.2,0.8-0.4,1.6-0.5,2.4c-0.2,1.3-0.4,2.6-0.4,3.9
		c0,6.4,2.6,12.3,6.8,16.5l0.1,0.1l1.7,1.5l0.1,0c0.5,0.4,1,0.8,1.5,1.1l2.1,1.3c3.2,1.7,6.8,2.7,10.7,2.7c3.9,0,7.5-0.9,10.7-2.6
		c0.7-0.4,1.5-0.8,2.1-1.2c0.6-0.4,1.1-0.8,1.6-1.1l1.8-1.6c4.3-4.2,7-10,7-16.5C53.2,28.8,53.1,27.5,52.9,26.2z M49.9,22.7
		c0.2,0.6,0.4,1.2,0.5,1.7c0,0,0,0,0,0l-13,4.2c-0.1-0.7-0.3-1.3-0.6-1.9l13-4.2C49.9,22.6,49.9,22.6,49.9,22.7z M48.9,20.3
		l-14.4,3.6l-1-14.7C40.1,10.1,45.8,14.3,48.9,20.3z M36.2,28.3c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1
		c0-0.6,0.5-1.1,1.1-1.1C35.7,27.2,36.2,27.6,36.2,28.3z M34.3,34.3c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1
		c0-0.6,0.5-1.1,1.1-1.1C33.8,33.2,34.3,33.6,34.3,34.3z M29.1,8.8c0.1,0,0.2,0,0.2,0c0.2,0,0.4,0,0.6,0h0.3c0.2,0,0.4,0,0.8,0h0
		l0,13.6c-0.3,0-0.7-0.1-1-0.1c-0.3,0-0.7,0-1,0.1L29.1,8.8z M30,23.4c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
		c-0.6,0-1.1-0.5-1.1-1.1C28.9,23.9,29.4,23.4,30,23.4z M30,26.9c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1
		c-1.7,0-3.1-1.4-3.1-3.1C27,28.3,28.3,26.9,30,26.9z M26.9,33.2c0.6,0,1.1,0.5,1.1,1.1c0,0.6-0.5,1.1-1.1,1.1
		c-0.6,0-1.1-0.5-1.1-1.1C25.7,33.6,26.2,33.2,26.9,33.2z M24.9,29.4c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
		c0.6,0,1.1,0.5,1.1,1.1C26.1,28.9,25.6,29.4,24.9,29.4z M26.7,9l-1.1,14.7v0h0l-14.4-3.7C14.4,14.2,20.1,10.1,26.7,9z M9.6,24.1
		c0.1-0.6,0.4-1.1,0.6-1.7c0,0,0-0.1,0-0.1l13,4.3c-0.3,0.6-0.5,1.2-0.6,1.9L9.6,24.1C9.6,24.2,9.6,24.2,9.6,24.1z M14.8,44.8
		c-3.9-4-6.1-9.3-6.1-14.9c0-1.1,0.1-2.2,0.3-3.3l0.1,0l13.5,5.6L14.8,44.8z M18.2,47.6L18.2,47.6c-0.6-0.4-1.1-0.7-1.5-1.1
		l-0.1-0.1l8-11c0.5,0.5,1,0.9,1.6,1.2L18.2,47.6z M30,51.2c-3.4,0-6.6-0.8-9.6-2.3l9.5-11.3L39.5,49C36.6,50.5,33.3,51.2,30,51.2z
		 M43.2,46.6c-0.5,0.4-1,0.7-1.5,1.1c0,0,0,0,0,0l-8-11.1c0.6-0.3,1.1-0.7,1.6-1.2L43.2,46.6C43.2,46.6,43.2,46.6,43.2,46.6z
		 M45.1,45l-7.7-12.7L51,26.8c0.2,1.1,0.3,2.2,0.2,3.3C51.2,35.7,49,41,45.1,45z"
        />
      </g>
    </svg>
  );
};

Wheel.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

export default Wheel;
