import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Stopwatch = ({
  width = "34",
  height = "38",
  strokeWidth = "2",
  strokeColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14.908 1h3.974M16.895 1v3.974M28.817 4.974l1.987 1.987M30.804 6.961l-3.974 3.974M8.948 12.922l1.987 1.986M16.895 32.791v-3.974M8.948 28.817l1.987-1.987M28.817 20.869h-3.974M24.843 28.817l-1.987-1.987M24.843 12.922l-1.987 1.986M16.895 4.974v15.895H1c0 8.78 7.117 15.896 15.895 15.896 8.78 0 15.896-7.117 15.896-15.896S25.674 4.974 16.895 4.974z" />
      </g>
    </svg>
  );
};

Stopwatch.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default Stopwatch;
