import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import propTypes from "prop-types";
import Icon from "../../../Icon";

const BidInputError = (props) => {
  const {
    className,
    icon,
    errorMessage = "There was an error with your entry",
  } = props;
  const theme = useContext(ThemeContext);
  const iconProps = icon
    ? icon
    : {
        type: "alert-circle-alt",
        strokeColour: theme.COLOURS.WHITE,
      };
  return (
    <div className={className}>
      <Icon {...iconProps} />
      <span dangerouslySetInnerHTML={{ __html: errorMessage }} />
    </div>
  );
};

BidInputError.propTypes = {
  icon: propTypes.shape({
    type: propTypes.string,
    strokeColour: propTypes.string,
  }),
  errorMessage: propTypes.string,
};

export default BidInputError;
