import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const CarEstate = ({
  width = "45",
  height = "23",
  strokeWidth = "2",
  strokeColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 45 23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M35.42 17.044h1.853c1.171 0 2.28-.532 3.01-1.447l1.798-2.245a3.43 3.43 0 00-2.677-5.57M13.191 17.044H28.01M29.863 7.781l-3.65-5.84A3.335 3.335 0 0023.38.372H8.56A6.688 6.688 0 002.89 3.52L1.202 6.21a5.4 5.4 0 00-.617 3.388l1.125 5.63a2.26 2.26 0 002.223 1.816h1.853M.63 7.781h38.774" />
        <circle cx="31.715" cy="17.044" r="3.705" />
        <circle cx="9.484" cy="17.044" r="3.705" />
        <path d="M18.747 7.781V.371M12.263.371l-2.779 7.41M22.452 11.486h-1.853M42.82 11.486h-3.694" />
      </g>
    </svg>
  );
};

CarEstate.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default CarEstate;
