import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Picture = ({
  width = "33",
  height = "24",
  strokeWidth = "2",
  strokeColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect x=".17" y=".134" width="30.075" height="21.721" rx="3.58" />
        <path d="M5.182 16.842l5.474-10.949c.23-.458.697-.747 1.21-.747.518 0 1 .271 1.268.715l4.613 7.64 1.971-2.674c.314-.423.81-.67 1.337-.668.512 0 .98.289 1.21.747l2.967 5.936H5.182z" />
      </g>
    </svg>
  );
};

Picture.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default Picture;
