import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Hammer = ({
  width = "19",
  height = "19",
  strokeWidth = "2",
  strokeColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.WHITE;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1.44 10.43a1.172 1.172 0 011.658-1.657l4.313 4.312a1.172 1.172 0 11-1.658 1.658L1.441 10.43zM8.402 3.47a1.172 1.172 0 011.657-1.658l4.313 4.312a1.172 1.172 0 11-1.662 1.654L8.402 3.47z" />
        <path d="M3.264 8.938l5.303-5.304 3.978 3.978-5.303 5.303zM9.23 10.927l5.967 6.63a1.406 1.406 0 001.988-1.99l-6.629-5.965" />
      </g>
    </svg>
  );
};

Hammer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default Hammer;
