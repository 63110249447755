import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../state/reducers/global";
import ModalInsert from "../../../views/components/ModalInsert";
import Panel from "../../../views/components/Panel";
import VendorSiteInformationModal from "../../components/VendorSiteInformationModal";
 
const VendorSiteInformationContainer = () => {
  const dispatch = useDispatch();
 
  const onCancel = () => {
    dispatch(closeModal());
  };
 
  return (
    <Fragment>
      <ModalInsert>
        <Panel
          style={{ height: "100%", borderRadius: "0.5rem" }}
          actions={[
            {
              content: "Close",
              action: onCancel,
              type: "ghost",
            },
          ]}
        >
          <VendorSiteInformationModal />
        </Panel>
      </ModalInsert>
    </Fragment>
  );
};
 
export default VendorSiteInformationContainer;