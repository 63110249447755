import React from "react";
import HeaderText from "../../../views/components/HeaderText";
import GenericInformation from "../../../views/components/GenericInformationSection";
import {buyerInfoColumns, modalTitle, buyerDetailsColumns} from "./const"
import MapMultiLineText from "../../../views/components/MapMultiLineText/index.jsx";
import { useSelector } from 'react-redux';
import colours from "../../../views/theme/base/colours";
import spacing from "../../../views/theme/base/spacing";
import Icon from "../../../views/components/Icon";

const VendorSiteInformationModal = ({
    className,
}) => {
    const getFormattedValue = (key, value) => {
        switch (key) {
            case "buyerAddress":
                return <MapMultiLineText text={value} />
            case "buyerEmail":
            return (<span
                style={{
                display: "flex",
                position: "relative",
                alignItems: "center",
                color: colours.PRIMARY.base,
                }}
            >
                {value}
                {value !== "-" ? (
                    <span
                    className="icon"
                    onClick={() => window.location.href = `mailto:${value}`}
                    style={{
                        cursor: "pointer",
                        verticalAlign: "middle",
                        marginLeft: spacing.XSMALL,
                        marginBottom: "-0.7rem",
                    }}
                    >
                    <Icon
                        type="envelope"
                        width="24"
                        height="24"
                        strokeColour={colours.PRIMARY.base}
                    />
                </span>
                ) : (null)}
            </span>);
            default:
                return value !== null && value !== undefined && value !== "" ? value : "-";
        }
    };

    const siteInformation = useSelector((state) => state.vendorInformation.siteDetails.data);

    const formattedData = {
        buyerName: siteInformation.name || "-",
        buyerAddress: siteInformation.addressLines,
        buyerStatus: siteInformation.status || "-",
        mainContact: siteInformation.mainContact || "-",
        telephoneNumber: siteInformation.phone || "-",
        dealerCode: siteInformation.dealerCode || "-",
        buyerEmail: siteInformation.email || "-",
    }

    return (
        <div className={className}>
            <HeaderText>{modalTitle}</HeaderText>
            <div className="row">
                <div className="buyer-information-div">
                    <GenericInformation
                        className={className}
                        informationData={formattedData}
                        columns={buyerInfoColumns}
                        getFormattedValue={getFormattedValue}
                    />
                </div>

                <div className="buyer-information-div">
                    <GenericInformation
                        className={className}
                        informationData={formattedData}
                        columns={buyerDetailsColumns}
                        getFormattedValue={getFormattedValue}
                    />
                </div>
            </div>
        </div>
    );
};

export default VendorSiteInformationModal;
