import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const CarFromTop = ({
  width = "150",
  height = "300",
  strokeWidth = "2",
  strokeColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.shades[60];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      enableBackground="new 0 0 150 300"
      version="1.1"
      viewBox="0 0 150 300"
    >
      <defs>
        <style>
          {`.st0{fill:none;stroke:${stroke};stroke-width:${strokeWidth};stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        .st1{fill:none;stroke:${stroke};stroke-width:${strokeWidth};stroke-miterlimit:10;}`}
        </style>
      </defs>
      <path
        d="M43 14.7l-2-7.5c18.9-5.8 47.3-5.8 66.1 0l-1.6 7.3-62.5.2z"
        className="st0"
      />
      <path
        d="M9.6 49.6c2.5-22.3 7.7-26 20.4-36.5C47.3-1 101-1 118.2 13.2c12.7 10.5 17.9 14.2 20.4 36.5 3.9 34.2-2.7 87.9-2.6 101.1.9 70.6 8.9 106.5-3.2 128.4-14.5 26.3-103.3 25.9-117.5 0-10.8-19.6-4.1-57.8-3.2-128.4.3-13.3-6.4-67-2.5-101.2z"
        className="st1"
      />
      <path
        d="M100.4 123.3c-12.3-1.7-40.3-1.7-52.6 0-11.2 1.5-15.9 3.8-18.8 5L18.5 88.8c.6-.7 1.8-1.3 3.7-2.2 4.3-1.9 11.9-4.9 23.7-7.5 16.9-3.6 39.4-3.6 56.4 0 11.8 2.5 19.3 5.6 23.7 7.5 1.9.8 3.1 1.5 3.7 2.2l-10.1 39.1c-3-1.2-8-3-19.2-4.6zM131.8 176.1l-9.4 3.2 1.6-41.7 9.3-40.8s.4 4-.3 22.2c-.5 11.5-1.9 27.3-1.9 37.5.1 9.6.4 16.2.7 19.6zM132.1 180.4l-9.8 3.6-1.1 34.9s.9 4.3 4.5 11.5c4.2 8.2 9 10.4 9 10.4s-1.5-47.4-2.6-60.4zM17 176.1l9.4 3.2-1.6-41.7-9.3-40.8s-.4 4 .3 22.2c.5 11.5 1.9 27.3 1.9 37.5 0 9.6-.4 16.2-.7 19.6zM16.8 180.4l9.8 3.6 1.1 34.9s-.9 4.3-4.5 11.5c-4.2 8.2-9 10.4-9 10.4s1.5-47.4 2.6-60.4zM55.5 289.6h37.3c1.1 0 2 .9 2.6 2.3-11.7 1.8-21.3 1.5-21.3 1.5s-9.6.3-21.2-1.5c.6-1.3 1.5-2.3 2.6-2.3z"
        className="st0"
      />
      <path
        d="M25.3 20.8c-4.1 3.1-7.6 9.4-5.1 11.4 2.5 2.1 10-.8 14.1-3.9 4.1-3.1 5.3-7.3 2.9-9.4-2.5-2-7.8-1.2-11.9 1.9zM122.8 20.5c4 2.9 8.7 9.2 6.3 11.1-2.4 1.9-11.1-1.2-15.1-4.1-4-2.9-5.3-6.8-2.8-8.7 2.3-1.9 7.6-1.1 11.6 1.7z"
        className="st1"
      />
      <path
        d="M117.2 236.3c2.1 9.6.9 18 .9 18s-8.8 2.7-21.4 4.3c-9.7 1.2-34.1 1.2-43.8 0-12.6-1.6-21.5-4.3-21.5-4.3s-1.3-8.3.8-17.9c7.4 1.3 21.4 3.1 42.5 3.1 19.7 0 34.5-1.9 42.5-3.2z"
        className="st0"
      />
    </svg>
  );
};

CarFromTop.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default CarFromTop;
