import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const CalendarDots = ({
  width = "34",
  height = "34",
  strokeColour,
  strokeWidth,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.shades[40];
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50 50"
    >
      <g
        fill={stroke}
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          d="M46.3,10.9c0,10.4,0,20.9,0,31.4c0,2-0.9,4-4,4c-11.6,0-23.1,0-34.7,0c-3.5,0-4-1.9-4-5c0-9.8,0-19.5,0-29.3
		c0-2.8,2-5.1,5-5.1c0.6,0,1.3,0,2.1,0c0-1.2,0-2.2,0-3.1c1.2,0,2.1,0,3.2,0c0,1.1,0,2.1,0,3.1c7.4,0,14.6,0,22,0c0-1.1,0-2.1,0-3.2
		c1.2,0,2.1,0,3.3,0c0,1.1,0,2,0,3.2c0.7,0,1.3,0,1.9,0C44.3,6.8,46.3,8.1,46.3,10.9z M7,19.3c0,7.2,0,14.2,0,21.1
		C7,42.5,7.5,43,9.5,43c10.3,0,20.6,0,31,0c2.1,0,2.5-0.5,2.5-2.5c0-6.6,0-13.1,0-19.7c0-0.5-0.1-1-0.1-1.5
		C31,19.3,19.1,19.3,7,19.3z M36,10.3c-7.4,0-14.7,0-22.1,0c0,1.1,0,2.1,0,3.1c-1.1,0-2.1,0-3.2,0c0-1.1,0-2.1,0-3.1
		c-3.5,0-3.5,0-3.5,5.5c11.9,0,23.8,0,35.8,0c0-5.4,0-5.4-3.8-5.4c0,1,0,2,0,2.9c-1.2,0-2.1,0-3.2,0C36,12.3,36,11.3,36,10.3z"
        />
        <path
          fill={"#fff"}
          d="M7,19.3c12.1,0,24.1,0,36.1,0c0,0.5,0,1,0,1.5c0,6.6,0,13.1,0,19.7c0,2.1-0.5,2.5-2.5,2.5c-10.3,0-20.6,0-31,0
		C7.5,43,7,42.5,7,40.4C7,33.4,7,26.4,7,19.3z M10.2,22.9c0,1,0,2,0,3c1.1,0,2.1,0,3,0c0-1.1,0-2,0-3C12.1,22.9,11.2,22.9,10.2,22.9
		z M19.9,25.9c0-1.1,0-2,0-3c-1.1,0-2,0-3,0c0,1.1,0,2,0,3C17.9,25.9,18.9,25.9,19.9,25.9z M26.6,22.9c-1.1,0-2.1,0-3.1,0
		c0,1.1,0,2,0,3c1.1,0,2,0,3.1,0C26.6,24.9,26.6,24,26.6,22.9z M33.2,22.9c-1.2,0-2.1,0-3.1,0c0,1.1,0,2,0,3c1.1,0,2.1,0,3.1,0
		C33.2,24.9,33.2,23.9,33.2,22.9z M39.9,22.9c-1.1,0-2,0-3.1,0c0,1,0,2,0,3c1.1,0,2,0,3.1,0C39.9,24.9,39.9,24,39.9,22.9z
		 M10.2,29.5c0,1.1,0,2.1,0,3c1.1,0,2,0,3,0c0-1.1,0-2,0-3C12.2,29.5,11.2,29.5,10.2,29.5z M19.9,29.6c-1.1,0-2,0-3.1,0c0,1,0,2,0,3
		c1.1,0,2,0,3.1,0C19.9,31.6,19.9,30.7,19.9,29.6z M26.6,29.6c-1.1,0-2.1,0-3.1,0c0,1.1,0,2,0,3c1.1,0,2,0,3.1,0
		C26.6,31.5,26.6,30.6,26.6,29.6z M33.2,32.6c0-1.1,0-2,0-3c-1.1,0-2.1,0-3.1,0c0,1.1,0,2,0,3C31.2,32.6,32.2,32.6,33.2,32.6z
		 M39.8,32.6c0-1.1,0-2.1,0-3c-1.1,0-2.1,0-3,0c0,1.1,0,2,0,3C37.9,32.6,38.8,32.6,39.8,32.6z M10.2,36.2c0,1.2,0,2.1,0,3
		c1.1,0,2.1,0,3,0c0-1.1,0-2,0-3C12.1,36.2,11.2,36.2,10.2,36.2z M16.8,39.2c1.1,0,2.1,0,3.1,0c0-1,0-2,0-2.9c-1.1,0-2.1,0-3.1,0
		C16.8,37.3,16.8,38.3,16.8,39.2z M26.5,39.3c0-1.1,0-2.1,0-3c-1.1,0-2,0-3,0c0,1.1,0,2,0,3C24.5,39.3,25.5,39.3,26.5,39.3z
		 M33.2,39.3c0-1.1,0-2.1,0-3c-1.1,0-2,0-3,0c0,1.1,0,2,0,3C31.2,39.3,32.1,39.3,33.2,39.3z"
        />
        <path d="M10.2,22.9c1,0,2,0,3,0c0,1,0,2,0,3c-1,0-1.9,0-3,0C10.2,24.9,10.2,23.9,10.2,22.9z" />
        <path d="M19.9,25.9c-1,0-2,0-3,0c0-1,0-2,0-3c1,0,2,0,3,0C19.9,23.9,19.9,24.9,19.9,25.9z" />
        <path d="M26.6,22.9c0,1.1,0,2,0,3c-1,0-2,0-3.1,0c0-1,0-1.9,0-3C24.5,22.9,25.5,22.9,26.6,22.9z" />
        <path d="M33.2,22.9c0,1,0,1.9,0,3c-1,0-2,0-3.1,0c0-0.9,0-1.9,0-3C31.1,22.9,32.1,22.9,33.2,22.9z" />
        <path d="M39.9,22.9c0,1.1,0,2,0,3c-1,0-2,0-3.1,0c0-1,0-2,0-3C37.8,22.9,38.8,22.9,39.9,22.9z" />
        <path d="M10.2,29.5c1.1,0,2,0,3,0c0,1,0,2,0,3c-1,0-1.9,0-3,0C10.2,31.6,10.2,30.7,10.2,29.5z" />
        <path d="M19.9,29.6c0,1.1,0,2,0,3c-1,0-2,0-3.1,0c0-1,0-2,0-3C17.9,29.6,18.8,29.6,19.9,29.6z" />
        <path d="M26.6,29.6c0,1,0,2,0,3c-1,0-2,0-3.1,0c0-1,0-1.9,0-3C24.5,29.6,25.5,29.6,26.6,29.6z" />
        <path d="M33.2,32.6c-1.1,0-2,0-3.1,0c0-1,0-1.9,0-3c1,0,2,0,3.1,0C33.2,30.6,33.2,31.5,33.2,32.6z" />
        <path d="M39.8,32.6c-1,0-1.9,0-3,0c0-1,0-1.9,0-3c0.9,0,1.9,0,3,0C39.8,30.5,39.8,31.5,39.8,32.6z" />
        <path d="M10.2,36.2c1,0,1.9,0,3,0c0,1,0,1.9,0,3c-0.9,0-1.9,0-3,0C10.2,38.3,10.2,37.4,10.2,36.2z" />
        <path d="M16.8,39.2c0-1,0-1.9,0-2.9c1,0,2,0,3.1,0c0,1,0,1.9,0,2.9C18.9,39.2,17.9,39.2,16.8,39.2z" />
        <path d="M26.5,39.3c-1.1,0-2,0-3,0c0-1,0-2,0-3c1,0,2,0,3,0C26.5,37.2,26.5,38.2,26.5,39.3z" />
        <path d="M33.2,39.3c-1.1,0-2,0-3,0c0-1,0-2,0-3c1,0,1.9,0,3,0C33.2,37.2,33.2,38.2,33.2,39.3z" />
      </g>
    </svg>
  );
};

CalendarDots.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default CalendarDots;
