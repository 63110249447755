import React from "react";
import { ItemContainer, ItemLabel, ItemContent } from "../index";
import { paragraphText, subHeaderText } from "../consts";
import ParagraphText from "../../ParagraphText";
import SubHeaderText from "../../SubHeaderText";
import SupportingDocumentLinks from "./SupportingDocumentLinks";

const SupportingDocumentLink = ({
  supportingLink: claimSupportingLink,
  disabled,
  urls,
  options,
}) => {
  return (
    <ItemContainer>
      <ItemLabel>
        <SubHeaderText>{subHeaderText.SUPPORTING_DOCUMENT_LINKS}</SubHeaderText>
        <div style={{ width: "80%" }}>
          <ParagraphText className="info-text">
            {paragraphText.SUPPORTING_DOCUMENT_LINKS}
          </ParagraphText>
        </div>
      </ItemLabel>
      <ItemContent>
        <SupportingDocumentLinks
          options={options}
          urls={urls}
          disabled={disabled}
        />
      </ItemContent>
    </ItemContainer>
  );
};

export default SupportingDocumentLink;
