import React from "react";
import PropTypes from "prop-types";
import COLOURS from "../../../theme/base/colours";

const InfoCircleSolid = ({
  width = "18",
  height = "18",
  fill = COLOURS.BLUE,
}) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle fill={fill} cx="9" cy="9" r="9" />
      <path
        d="M8.716 7.707c.43 0 .76.098.994.292a.945.945 0 01.35.76c0 .064-.01.177-.025.34a2.154 2.154 0 01-.09.447l-.444 1.478a3.967 3.967 0 00-.098.406 2.022 2.022 0 00-.043.346c0 .196.046.33.14.401.093.072.256.107.486.107a2.186 2.186 0 00.665-.147l-.119.456c-.357.133-.641.233-.855.303a2.39 2.39 0 01-.741.104c-.433 0-.77-.1-1.009-.297a.935.935 0 01-.359-.755c0-.118.009-.24.027-.363.018-.124.047-.263.086-.419l.447-1.483c.04-.142.073-.277.1-.403.027-.127.04-.243.04-.35 0-.188-.041-.32-.124-.395-.084-.074-.242-.11-.478-.11a1.33 1.33 0 00-.355.049c-.12.035-.225.066-.311.097l.118-.457c.293-.112.573-.208.84-.287.268-.08.52-.12.758-.12zM9.704 5c.292 0 .543.091.75.274a.85.85 0 010 1.318 1.1 1.1 0 01-.75.272c-.291 0-.543-.09-.752-.272a.844.844 0 01-.313-.658c0-.257.106-.477.313-.66.21-.183.46-.274.752-.274z"
        fill={COLOURS.WHITE}
      />
    </g>
  </svg>
);

InfoCircleSolid.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

export default InfoCircleSolid;
