import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const Trash = ({
  width = "24",
  height = "24",
  strokeWidth = "2",
  strokeColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.shades[40];
  return (
    <svg
      viewBox="0 0 24 24"
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M20.25 5.125L18.589 20.9a1.833 1.833 0 01-1.822 1.642H7.233A1.833 1.833 0 015.41 20.9L3.75 5.125M1.458 5.125h21.084M7.875 5.125v-2.75c0-.506.41-.917.917-.917h6.416c.507 0 .917.41.917.917v2.75M12 9.25v9.625M16.125 9.25l-.458 9.625M7.875 9.25l.458 9.625" />
      </g>
    </svg>
  );
};

Trash.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default Trash;
