export const ACCEPT_BID = {
    headerText: "Bid Accepted",
    bodyText: "The bid has been accepted.",
}

export const DECLINE_BID = {
    headerText: "Bid Declined",
    bodyText: "The bid has been declined.",
}

export const DELETE_DOCUMENT = {
    headerText: "Document Deleted",
    bodyText: "The document has been deleted."
}

export const RELEASE_HOLD = {
    headerText: "Hold Released",
    bodyText: "Vehicle hold has been released."
}

export const RELEASE_WORKSHOP = {
    headerText: "Workshop Released",
    bodyText: "Vehicle has been released from workshop."
}

export const COPY_DRIVER_LOCATION = {
    headerText: "Driver Location Copied",
    bodyText: "The driver location has been copied."
}

export const COPY_CUSTOMER_LOCATION = {
    headerText: "Customer Location Copied",
    bodyText: "The customer location has been copied."
}

export const SAVE_LOCATION = {
    headerText: "Location Saved",
    bodyText: "The location has been saved."
}

export const REMOVE_FROM_SALE = {
    headerText: "Vehicle Removed From Sale",
    bodyText: "The vehicle has been removed from sale."
}

export const ADD_WORKSHOP = {
    headerText: "Vehicle Placed into Workshop",
    bodyText: "The vehicle has been placed into workshop."
}

export const HOLD_VEHICLE = {
    headerText: "Vehicle Placed on Hold",
    bodyText: "The vehicle has been placed on hold."
}

export const MODIFY_HOLD = {
    headerText: "Vehicle Hold Modified",
    bodyText: "The vehicle hold has been modified."
}

export const EVENT_HISTORY = {
    headerText: "Event History Added",
    bodyText: "Event history has been added."
}

export const CANCEL_PAYMENT = {
    headerText: "Payment Cancelled",
    bodyText: "Payment has been cancelled."
}

export const CANCEL_RETURN = {
    headerText: "Return Cancelled",
    bodyText: "The return has been cancelled."
}

export const DELETE_ALL_IMAGES = {
    headerText: "Images Deleted",
    bodyText: "All images have been deleted."
}

export const EDIT_SALES_INFO = {
    headerText: "Sales Information Edited",
    bodyText: "Sales information has been edited."
}

export const MODIFY_VEHICLE_DETAILS = {
    headerText: "Modifying Vehicle Details",
    bodyText: "Vehicle details have been modified."
}

export const CANCEL_DEFLEET = {
    headerText: "Defleet Cancelled",
    bodyText: "The defleet has been cancelled."
}

export const GET_GENERIC_COLOUR = {
    headerText: "Generic Colour Retrieved",
    bodyText: "The generic colour has been retrieved."
}

export const RECEIVE_PAYMENT = {
    headerText: "Payment Received",
    bodyText: "Payment has been marked as received."
}

export const PUBLISH = {
    headerText: "Vehicle Published",
    bodyText: "The vehicle has been published."
}

export const RETURN_VEHICLE = {
    headerText: "Vehicle Returned",
    bodyText: "The vehicle has been marked as returned."
}

export const INCLUDE_IN_AUTO_PROFILING = {
    headerText: "Include In Auto Profiling",
    bodyText: "Include in auto profiling successful."
}

export const REMOVE_AUTO_PROFILING = {
    headerText: "Remove Auto Profiling",
    bodyText: "Removal of auto profiling was successful."
}

export const TERMINATE_VEHICLE = {
    headerText: "Vehicle Defleeted",
    bodyText: "The vehicle has been defleeted."
}

export const CANCEL_SALE= {
    headerText: "Sale Cancelled",
    bodyText: "The sale has been cancelled."
    
}