import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const ArrowForward = ({
  strokeColour,
  width = "34",
  height = "33",
  strokeWidth = "2",
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 34 33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="matrix(-1 0 0 1 33 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <ellipse cx="16" cy="15.344" rx="16" ry="15.344" />
        <path d="M7.273 12.557h13.09c2.41 0 4.364 1.874 4.364 4.185 0 2.311-1.953 4.185-4.363 4.185h-2.182" />
        <path d="M11.636 16.74l-4.363-4.185 4.363-4.185" />
      </g>
    </svg>
  );
};

ArrowForward.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default ArrowForward;
