import { GetCapValuation, GetCapValuationWithValuationReason } from "../../api/CapValuation";
import { FetchMakes, FetchModels, VehicleLookup } from "../../api/Cap";
import { fetchVehicleSalesInfo } from "./vendor-vehicle/salesInformation";

// Action constants
export const FETCH_CAP = "@cap/FETCH_CAP";
export const FETCH_CAP_SUCCESS = "@cap/FETCH_CAP_SUCCESS";
export const FETCH_CAP_FAILURE = "@cap/FETCH_CAP_FAILURE";

export const FETCH_CAP_MAKES = "@cap/FETCH_CAP_MAKES";
export const FETCH_CAP_MAKES_SUCCESS = "@cap/FETCH_CAP_MAKES_SUCCESS";
export const FETCH_CAP_MAKES_FAILURE = "@cap/FETCH_CAP_MAKES_FAILURE";

export const FETCH_CAP_MODELS = "@cap/FETCH_CAP_MODELS";
export const FETCH_CAP_MODELS_SUCCESS = "@cap/FETCH_CAP_MODELS_SUCCESS";
export const FETCH_CAP_MODELS_FAILURE = "@cap/FETCH_CAP_MODELS_FAILURE";

export const FETCH_VEHICLE_LOOKUP = "@cap/FETCH_VEHICLE_LOOKUP";
export const FETCH_VEHICLE_LOOKUP_SUCCESS = "@cap/FETCH_VEHICLE_LOOKUP_SUCCESS";
export const FETCH_VEHICLE_LOOKUP_FAILURE = "@cap/FETCH_VEHICLE_LOOKUP_FAILURE";

export const CLEAR_VEHICLE_LOOKUP_DATA = "@cap/CLEAR_VEHICLE_LOOKUP_DATA";

export const CLEAR_CAP_DATA = "@cap/CLEAR_CAP_DATA";

// Action creators
const fetchCapSuccess = (data, vehicleId) => {
  return {
    type: FETCH_CAP_SUCCESS,
    payload: {
      data,
      vehicleId,
    },
  };
};
const fetchCapFailure = (error) => {
  return {
    type: FETCH_CAP_FAILURE,
    payload: error,
  };
};

export const fetchCap = (vehicleId) => (dispatch, getState) => {
  dispatch({ type: FETCH_CAP, payload: vehicleId });

  const capInstance = new GetCapValuation({
    params: { id: vehicleId },
    credentials: getState().authentication.credentials,
  });
  capInstance.call().then(
    (response) => {
      dispatch(fetchCapSuccess(response.data, vehicleId));
    },
    (err) => {
      return dispatch(fetchCapFailure(err));
    }
  );
};

export const fetchCapWithValuationReason = (vehicleId, type, valuationReason) => (dispatch, getState) => {
  dispatch({ type: FETCH_CAP, payload: vehicleId });

  const capInstance = new GetCapValuationWithValuationReason({
    params: {
      vehicleId: vehicleId,
      valuationReason: valuationReason,
    },
    credentials: getState().authentication.credentials,
  });

  capInstance.call().then(
    (response) => {
      if (type === "vendor-vehicleDetails") {
        dispatch(fetchVehicleSalesInfo(vehicleId));
      }
      dispatch(fetchCapSuccess(response.data, vehicleId));
    },
    (err) => {
      return dispatch(fetchCapFailure(err));
    }
  );
};

export const fetchCapMakes = () => (dispatch, getState) => {
  dispatch({ type: FETCH_CAP_MAKES });

  const capInstance = new FetchMakes({
    credentials: getState().authentication.credentials,
  });
  capInstance.call().then(
    (response) => {
      dispatch(fetchCapMakesSuccess(response.data));
    },
    (err) => {
      return dispatch(fetchCapMakesFailure(err));
    }
  );
};

const fetchCapMakesSuccess = (data) => {
  return {
    type: FETCH_CAP_MAKES_SUCCESS,
    payload: {
      data,
    },
  };
};
const fetchCapMakesFailure = (error) => {
  return {
    type: FETCH_CAP_MAKES_FAILURE,
    payload: error,
  };
};

export const fetchCapModels = (makeId) => (dispatch, getState) => {
  dispatch({ type: FETCH_CAP_MODELS });

  const capInstance = new FetchModels({
    params: {
      makeId: makeId,
    },
    credentials: getState().authentication.credentials,
  });
  capInstance.call().then(
    (response) => {
      dispatch(fetchCapModelsSuccess(makeId, response.data));
    },
    (err) => {
      return dispatch(fetchCapModelsFailure(err));
    }
  );
};

const fetchCapModelsSuccess = (makeId, models) => {
  return {
    type: FETCH_CAP_MODELS_SUCCESS,
    payload: {
      makeId,
      models,
    },
  };
};
const fetchCapModelsFailure = (error) => {
  return {
    type: FETCH_CAP_MODELS_FAILURE,
    payload: error,
  };
};

export const fetchVehicleLookup =
  (vehicleSelectionData) => (dispatch, getState) => {
    dispatch({ type: FETCH_VEHICLE_LOOKUP });

    const capInstance = new VehicleLookup({
      params: {
        capId: vehicleSelectionData.capId,
        capCode: vehicleSelectionData.capCode,
        makeId: vehicleSelectionData.chosenMakeId,
        modelId: vehicleSelectionData.chosenModelId,
        makeDescription: vehicleSelectionData.chosenMake,
        modelDescription: vehicleSelectionData.chosenModel,
        searchText: vehicleSelectionData.description,
      },
      credentials: getState().authentication.credentials,
    });

    capInstance.call().then(
      (response) => {
        dispatch(fetchVehicleLookupSuccess(response));
      },
      (err) => {
        dispatch(fetchVehicleLookupFailure(err.response.data));
      }
    );
  };

const fetchVehicleLookupSuccess = (data) => {
  return {
    type: FETCH_VEHICLE_LOOKUP_SUCCESS,
    payload: data,
  };
};

const fetchVehicleLookupFailure = (error) => {
  return {
    type: FETCH_VEHICLE_LOOKUP_FAILURE,
    payload: error,
  };
};

export const clearVehicleLookupData = () => {
  return {
    type: CLEAR_VEHICLE_LOOKUP_DATA
  }
}

export const clearVehicleCapData = () => {
  return {
    type: CLEAR_CAP_DATA
  }
}

const initialState = {
  error: false,
  isFetchingCapMakes: false,
  isFetchingCapModels: false,
  isFetchingVehicleLookupData: false,
  isFetching: false,
  fetchingCap: {
    byVehicleId: {},
  },
  capData: {
    byVehicleId: {},
  },
  vehicleLookupData: [],
  capMakes: [],
  capModels: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CAP:
      return {
        ...state,
        fetchingCap: {
          ...state.fetchingCap,
          byVehicleId: {
            ...state.fetchingCap.byVehicleId,
            [action.payload]: {
              isFetching: true,
            },
          },
        },
      };
    case FETCH_CAP_SUCCESS:
      return {
        ...state,
        fetchingCap: {
          ...state.fetchingCap,
          byVehicleId: {
            ...state.fetchingCap.byVehicleId,
            [action.payload.vehicleId]: {
              isFetching: false,
            },
          },
        },
        capData: {
          ...state.capData,
          byVehicleId: {
            ...state.capData.byVehicleId,
            [action.payload.vehicleId]: {
              ...action.payload.data,
            },
          },
        },
      };
    case FETCH_CAP_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case FETCH_CAP_MAKES:
      return {
        ...state,
        isFetchingCapMakes: true,
      };
    case FETCH_CAP_MAKES_SUCCESS:
      return {
        ...state,
        capMakes: action.payload.data,
        isFetchingCapMakes: false,
      };
    case FETCH_CAP_MAKES_FAILURE:
      return {
        ...state,
        isFetchingCapMakes: false,
        error: action.payload,
      };
    case FETCH_CAP_MODELS:
      return {
        ...state,
        isFetchingCapModels: true,
      };
    case FETCH_CAP_MODELS_SUCCESS:
      const { makeId, models } = action.payload;
      return {
        ...state,
        capModels: {
          ...state.capModels,
          [makeId]: models,
        },
        isFetchingCapModels: false,
      };
    case FETCH_CAP_MODELS_FAILURE:
      return {
        ...state,
        isFetchingCapModels: false,
        error: action.payload,
      };
    case FETCH_VEHICLE_LOOKUP:
      return {
        ...state,
        isFetchingVehicleLookupData: true,
      };
    case FETCH_VEHICLE_LOOKUP_SUCCESS:
      return {
        ...state,
        vehicleLookupData: action.payload.data,
        isFetchingVehicleLookupData: false,
      };
    case FETCH_VEHICLE_LOOKUP_FAILURE:
      return {
        ...state,
        isFetchingVehicleLookupData: false,
        error: action.payload,
      };
      case CLEAR_VEHICLE_LOOKUP_DATA: 
      return {
        ...state,
        vehicleLookupData: [],
      }
      case CLEAR_CAP_DATA:
      return {
        ...state,
        ...initialState,
      }
    default:
      return state;
  }
};

export default reducer;
