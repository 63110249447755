import React, { useContext } from "react";
import classnames from "classnames";
import HeaderText from "../HeaderText";
import ParagraphText from "../ParagraphText";
import Button from "./../Button";
import Icon from "../Icon";
import { ThemeContext } from "styled-components";

const GenericConfirmInsert = ({
  onCancel,
  onConfirm,
  headerText,
  bodyText,
  additionalText,
  qaClassName,
  className,
  confirmBtnText = "Confirm",
  closeBtnText = "Close",
}) => {
  const theme = useContext(ThemeContext);
  return (
    <div className={classnames(className, qaClassName)}>
      <div className="text-area">
        <Icon
          type="alert-circle"
          strokeColour={theme.COLOURS.ORANGE.base}
          height="52px"
          width="52px"
        />
        <HeaderText>{headerText}</HeaderText>
        <ParagraphText>{bodyText}</ParagraphText>
        {additionalText && additionalText}
      </div>
      <div className="actions">
        <Button
          primary
          ghost
          action={() => onCancel()}
          content={closeBtnText}
        />
        <Button primary content={confirmBtnText} action={() => onConfirm()} />
      </div>
    </div>
  );
};

export default GenericConfirmInsert;
