export const paragraphText = {
  HEADER_TEXT: "Cancel Sale",
  SUB_HEADER_TEXT:
    "Please complete the details below to cancel this vehicle sale.",
  CONTENT_TEXT:
    "Our records indicate this purchase has a delivery fee and/or admin fee against it. Please select what you wish to credit back to the trade buyer.",
  DROPDOWN_TEXT: "Reason For Cancellation",
  REGISTRATION: "Registration",
  TRADE_BUYER_NAME: "Trade Buyer",
  CASE_REFERENCE: "Case Reference",
  VEHICLE_REFERENCE: "Vehicle Reference",
  PURCHASE_PRICE: "Purchase Price",
  RESIDUAL_VALUE: "Residual Value",
  CAP_VALUE: "CAP Value",
  PAYMENT_STATUS: "Payment Status",
  NOTES: "Notes",
  WARNING:
    "By cancelling this sale you are also cancelling an outstanding logistics request.",
  CANCEL_NOT_POSSIBLE:
    "Sale Cancellation is not possible as there is another vehicle with the same registration active within 1link. Please correct this before returning to cancel this sale.",
  OUTSTANDING_CLAIM: 
    "Please note, the vehicle purchase has an outstanding Claim, continuing to cancel this sale will automatically complete this Claim.",
};

export const radioOptions = {
  VEHICLE_PURCHASE_PRICE: "Vehicle Purchase Price Only",
  INVOICE_AMOUNT: "Full Invoice Amount",
};

export const categoryDropdown = {
  PLACEHOLDER: "Reason for cancellation",
};
