import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import InputRadio from "../InputRadio";

const BucketTypeFilter = ({
  className,
  action,
  isFetching,
  selectedValue =  "all",
  options = [],
}) => {
  return (
    <div className={className}>
      <ul className={classnames("action__container", isFetching && "disabled")}>
        {options.length &&
          options.map((el, idx) => (
            <li
              key={idx}
              className={classnames(
                "action__button",
                selectedValue === el.value && "checked"
              )}
            >
              <InputRadio
                value={el.value}
                label={el.label}
                onInputChange={() => action(el.value)}
              />
            </li>
          ))}
      </ul>
    </div>
  );
};

BucketTypeFilter.propTypes = {
  className: PropTypes.string,
  selectedValue: PropTypes.string,
  action: PropTypes.func,
  isFetching: PropTypes.bool,
  options: PropTypes.array,
};

export default BucketTypeFilter;
