import React from "react";
import Loader from "../../components/Loader";

const LoaderModalInsert = ({
  title = "Processing your request",
  content = "We are just processing your request, please wait.",
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader title={title} content={content} />
    </div>
  );
};

export default LoaderModalInsert;
