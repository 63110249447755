import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const CarConvertible = ({
  width = "40",
  height = "20",
  strokeWidth = "2",
  strokeColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        transform="translate(1 1)"
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M32.466 14.859h4.397a.812.812 0 00.812-.812v-2.436a6.496 6.496 0 00-6.496-6.496h-7.307c-.897 0-1.624.727-1.624 1.624H14.44c-.863 0-1.69-.342-2.3-.953-.919-.914-3.458-.67-6.131-.67-4.06 0-5.684 2.46-5.684 6.495v2.436c0 .448.363.812.812.812h3.585" />
        <circle cx="28.744" cy="13.235" r="4.06" />
        <circle cx="8.444" cy="13.235" r="4.06" />
        <path d="M25.022 14.859H12.166M23.872 5.115L19 .244M12.92 1.861l2.436 4.872" />
      </g>
    </svg>
  );
};

CarConvertible.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default CarConvertible;
