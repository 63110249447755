import React, { Fragment, useContext } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

import SubHeaderText from "../SubHeaderText";
import Icon from "../Icon";
import ParagraphText from "../ParagraphText";
import Button from "../Button";

const Notification = ({
  className,
  qaClassname = "qa-notification-message",
  qaClassnameTitle = "qa-notification-message__notification-title",
  onBtnPrimaryClick = () => {},
  onBtnSecondaryClick = () => {},
  onModalClose = () => {},
  title,
  text,
  isAlert,
  size,
  type,
  overrideIcon,
  hasActions,
  btnPrimaryLabel,
  btnSecondaryLabel,
  isModal,
}) => {
  const theme = useContext(ThemeContext);
  // Handle icon styling considerations
  const getIconProps = () => {
    const iconTypeMap = {
      success: "check-circle",
      caution: "alert-circle",
      error: "alert-triangle",
      stopwatch: "stopwatch"
    };
    const iconStrokeColourMap = {
      success: theme.COLOURS.GREEN.base,
      caution: theme.COLOURS.ORANGE.base,
      error: theme.COLOURS.RED.base,
      stopwatch: theme.COLOURS.ACCENT.base
    };
    return {
      type: `${iconTypeMap[type] || "info-circle"}`,
      strokeColour: isAlert
        ? theme.COLOURS.WHITE
        : iconStrokeColourMap[type] || theme.COLOURS.ACCENT.base
    };
  };

  const icon = overrideIcon ? overrideIcon : getIconProps();

  // Group button props into spreadable objects
  const legalStates = ["success", "caution", "error"];
  const hasLegalState = legalStates.indexOf(type) !== -1;
  const isActionCallable = action => typeof action === "function";

  const renderActionButtons = () => {
    const commonBtnPrimaryProps = {
      content: btnPrimaryLabel,
      [isActionCallable(onBtnPrimaryClick)
        ? "action"
        : "url"]: onBtnPrimaryClick,
      [hasLegalState ? `${type}Primary` : "primary"]: true
    };
    const commonBtnSecondaryProps = {
      content: btnSecondaryLabel,
      [isActionCallable(onBtnSecondaryClick)
        ? "action"
        : "url"]: onBtnSecondaryClick,
      [hasLegalState ? `${type}Secondary` : "ghost"]: true
    };
    return (
      <Fragment>
        <Button {...commonBtnPrimaryProps} />
        <Button {...commonBtnSecondaryProps} />
      </Fragment>
    );
  };

  const iconSize = size => {
    switch (size) {
      case "small":
        return "16";
      case "medium":
        return "33";
      default:
        return "48";
    }
  };

  return (
    <div
      className={classnames(qaClassname, className, {
        notification: !isAlert,
        alert: isAlert
      })}
    >
      {isModal && (
        <div
          className={classnames(
            "notification__close",
            `notification__close--${type}`
          )}
          onClick={onModalClose}
        />
      )}
      <div className="notification__icon">
        <Icon width={iconSize(size)} height={iconSize(size)} {...icon} />
      </div>
      <div className="notification__content">
        {title && (
          <SubHeaderText medium>
            <span className={qaClassnameTitle}>{title}</span>
          </SubHeaderText>
        )}

        {text &&
          (Array.isArray(text) ? (
            <div className="notification__multi-paragraph">
              {text.map((paragraph, index) => (
                <ParagraphText key={index}>{paragraph}</ParagraphText>
              ))}
            </div>
          ) : (
            <ParagraphText>{text}</ParagraphText>
          ))}

        {hasActions && (
          <div className="notification__actions">{renderActionButtons()}</div>
        )}
      </div>
    </div>
  );
};

Notification.propTypes = {
  className: PropTypes.string,
  qaClassname: PropTypes.string,
  qaClassnameTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  type: PropTypes.string,
  isAlert: PropTypes.bool,
  size: PropTypes.string,
  overrideIcon: PropTypes.shape({
    type: PropTypes.string,
    strokeColour: PropTypes.string
  }),
  hasActions: PropTypes.bool,
  btnPrimaryLabel: PropTypes.string,
  primaryHasAction: PropTypes.bool,
  onBtnPrimaryClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  btnSecondaryLabel: PropTypes.string,
  secondaryHasAction: PropTypes.bool,
  onBtnSecondaryClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  isModal: PropTypes.bool,
  onModalClose: PropTypes.func
};

export default Notification;
