import React, { useContext } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

const ListSort = ({
  width = "26",
  height = "17",
  strokeWidth = "2",
  strokeColour,
}) => {
  const theme = useContext(ThemeContext);
  const stroke = strokeColour ? strokeColour : theme.COLOURS.PRIMARY.base;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        stroke={stroke}
        strokeWidth={strokeWidth}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M25 1.667H1M19 8.667H1M13 15.667H1" />
      </g>
    </svg>
  );
};

ListSort.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  strokeColour: PropTypes.string,
  strokeWidth: PropTypes.string,
};

export default ListSort;
