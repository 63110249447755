import React, { useContext } from "react";
import propTypes from "prop-types";
import ParagraphText from "../ParagraphText";
import classnames from "classnames";
import Icon from "../Icon";
import { ThemeContext } from "styled-components";

const Timeline = (props) => {
  const {
    className = "timeline",
    qaClassName = "qa-timeline",
    vertical,
    data,
  } = props;
  const theme = useContext(ThemeContext);

  const colorMap = {
    SUCCESS: theme.COLOURS.GREEN.base,
    CAUTION: theme.COLOURS.ORANGE.base,
    ERROR: theme.COLOURS.RED.base,
  };

  const iconMap = {
    SUCCESS: "include",
    CAUTION: "exclamation-mark",
    ERROR: "exclude",
  };

  const getColorByStatus = (status) => {
    return colorMap[status];
  };

  const getIconByStatus = (status) => {
    return iconMap[status];
  };

  const entryText = (el) => (
    <div className="entry-text">
      <ParagraphText>{el.text}</ParagraphText>
      {el.additionalText && (
        <ParagraphText>
          <span style={{ color: getColorByStatus(el.status) }}>
            {el.additionalText}
          </span>
        </ParagraphText>
      )}
    </div>
  );

  return (
    <div className={classnames(className, qaClassName)}>
      {data.map((el) => (
        <div className="entry" key={el.text}>
          <div
            className="divider"
            style={{
              backgroundColor: getColorByStatus(el.status),
              borderColor: getColorByStatus(el.status),
            }}
          ></div>
          <div
            className="entry-bullet"
            style={{ borderColor: getColorByStatus(el.status) }}
          >
            <Icon
              type={getIconByStatus(el.status)}
              width="30"
              height="30"
              strokeColour={getColorByStatus(el.status)}
              strokeWidth="3"
            />
            {!vertical && entryText(el)}
          </div>
          {vertical && entryText(el)}
        </div>
      ))}
    </div>
  );
};

const timelineItemShape = {
  status: propTypes.oneOf(["SUCCESS", "CAUTION", "ERROR"]),
  text: propTypes.string,
  additionalText: propTypes.string,
};

const timelineConfigurationShape = {
  dividerSize: propTypes.number,
};

Timeline.propTypes = {
  className: propTypes.string,
  qaClassName: propTypes.string,
  data: propTypes.arrayOf(propTypes.shape(timelineItemShape)),
  configuration: propTypes.shape(timelineConfigurationShape),
  vertical: propTypes.bool,
};

export default Timeline;
